import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateHousing: [],
  countyHousing: [],
  zipHousing: [],
  apiErrors: [],
  error: false,
};

const HousingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HOUSING:
      return {
        ...state,
        isLoading: true,
        stateHousing: [],
        countyHousing: [],
        zipHousing: [],
      };

    case ActionTypes.HOUSING_SUCCESS:
      if (action.stateHousing) {
        return {
          ...state,
          isLoading: false,
          stateHousing: action.stateHousing,
          apiErrors: null,
        };
      } else if (action.countyHousing) {
        return {
          ...state,
          isLoading: false,
          countyHousing: action.countyHousing,
          apiErrors: null,
        };
      } else if (action.zipHousing) {
        return {
          ...state,
          isLoading: false,
          zipHousing: action.zipHousing,
          apiErrors: null,
        };
      }

    case ActionTypes.HOUSING_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipHousing: null,
        stateHousing: null,
        countyHousing: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default HousingReducer;


