import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateEducation: [],
  countyEducation: [],
  zipEducation: [],
  apiErrors: [],
  error: false,
};

const EducationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.EDUCATION:
      return {
        ...state,
        isLoading: true,
        stateEducation: [],
        countyEducation: [],
        zipEducation: [],
      };

    case ActionTypes.EDUCATION_SUCCESS:
      if (action.stateEducation) {
        return {
          ...state,
          isLoading: false,
          stateEducation: action.stateEducation,
          apiErrors: null,
        };
      } else if (action.countyEducation) {
        return {
          ...state,
          isLoading: false,
          countyEducation: action.countyEducation,
          apiErrors: null,
        };
      } else if (action.zipEducation) {
        return {
          ...state,
          isLoading: false,
          zipEducation: action.zipEducation,
          apiErrors: null,
        };
      }

    case ActionTypes.EDUCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipEducation: null,
        stateEducation: null,
        countyEducation: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default EducationReducer;
