import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const EDUCATION = "EDUCATION";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";
export const EDUCATION_FAILURE = "EDUCATION_FAILURE";

export const EducationAction = (payload) => async (dispatch) => {
  dispatch({
    type: EDUCATION,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/education`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    EDUCATION_FAILURE
  );
  if (!apiRequest.error) {
    if (payload.zipcode) {
      dispatch({ type: EDUCATION_SUCCESS, zipEducation: apiRequest.data });
    } else if (payload.county) {
      dispatch({
        type: EDUCATION_SUCCESS,
        countyEducation: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: EDUCATION_SUCCESS,
        stateEducation: apiRequest.data,
      });
    }
  }
};
