import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  data: [],
  apiErrors: [],
  error: false,
};

const CustomFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CUSTOM_FILTER:
      return {
        ...state,
        isLoading: true,
        data: [],
      };

    case ActionTypes.CUSTOM_FILTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.response,
        apiErrors: null,
      };

    case ActionTypes.CUSTOM_FILTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default CustomFilterReducer;
