import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { Tooltip, OverlayTrigger, Col } from "react-bootstrap";
import TooltipIcon from "../../assets/images/TooltipIcon.svg";
import { result } from "lodash";

const RaceBlock = ({ getRace, cssClass }) => {
  let race_title = {
    white: "White",
    hispanic: "Hispanic",
    "other race": "Other",
    asian: "Asian",
    aian: "American Indian/Alaska Native",
    black: "Black",
    nhpi: "Native Hawaiian/Pacific Islander",
  };
  const getRaceName = (race) => {
    return race_title[race];
  };
  return (
    <Col md={7} className="mb-5 pr-0">
      <div className={`RacePopup ${cssClass}`}>
        <p className="fw-bold text-wrap mb-3 pb-0 rp-label">
          % of Population by Race
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Data Source: US Census 2020</Tooltip>}>
            <img src={TooltipIcon} style={{ height: 17, width: 20 }} />
          </OverlayTrigger>
        </p>
        <table className="customTable">
          <tbody>
            {getRace.data.result &&
              Object.entries(getRace.data.result).map(([key, value], i) => (
                <tr className={`raceList ${_.lowerCase(key) == "other race" && "d-none"}`} key={key}>
                  <td>
                    <p
                      style={{
                        opacity: `0.${Math.abs(i - 9)}`,
                        backgroundColor: "#511284",
                        height: "20px",
                        width: "20px",
                        borderRadius: "10px",
                        // display:
                        //   "inline-block",
                        // position: "absolute",
                        margin: "3px 8px 0px 6px",
                      }}
                    ></p>
                  </td>
                  <td className="ListLabels">{`${value.toFixed(2)}% `}</td>
                  <td className="ListLabelSpan">{getRaceName(_.lowerCase(key))}</td>
                </tr>
              ))}
            <tr className="raceList" key={22}>
              <td>
                <p
                  style={{
                    opacity: `0.1`,
                    backgroundColor: "#511284",
                    height: "20px",
                    width: "20px",
                    borderRadius: "10px",
                    // display:
                    //   "inline-block",
                    // position: "absolute",
                    margin: "3px 8px 0px 6px",
                  }}
                ></p>
              </td>
              <td className="ListLabels">{`${getRace.data.result["Other_race"].toFixed(2)}% `}</td>
              <td className="ListLabelSpan">Other</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Col>
  );
};

// MobileMapBlock.propTypes = {
//   title: PropTypes.string,
//   age: PropTypes.number,
// };
export default RaceBlock;
