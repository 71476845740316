import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const SURVEY = "SURVEY";
export const SURVEY_SUCCESS = "SURVEY_SUCCESS";
export const SURVEY_FAILURE = "SURVEY_FAILURE";

export const SurveyAction = (payload) => async (dispatch) => {
  dispatch({
    type: SURVEY,
  });

  const apiRequest = await ManualAuthApiCall(
      `Anthem/surveys`,
      {
        method: "POST",
        body: payload,
      },
      dispatch,
      SURVEY_FAILURE
  );
  if (!apiRequest.error) {
    // console.log("API REQUEST", apiRequest);
    // console.log("API PAYLOAD", payload);
    dispatch({ type: SURVEY_SUCCESS, data: apiRequest.data });
  } else {
    dispatch({ type: SURVEY_FAILURE, apiError: "Error" });
  }
};
