import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const ANTHEM_STATE_PROGRAM = " ANTHEM_STATE_";
export const ANTHEM_STATE_PROGRAM_SUCCESS = "ANTHEM_STATE_PROGRAM_SUCCESS";
export const ANTHEM_STATE_PROGRAM_FAILURE = "ANTHEM_STATE_PROGRAM_FAILURE";

export const AnthemStateProgramAction = (payload) => async (dispatch) => {
  dispatch({
    type: ANTHEM_STATE_PROGRAM,
  });
  const apiRequest = await ManualAuthApiCall(
    `Anthem/partners`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
      ANTHEM_STATE_PROGRAM_FAILURE
  );
  if (!apiRequest.error) {
 if (payload.state) {
      dispatch({
        type: ANTHEM_STATE_PROGRAM_SUCCESS,
        statePrograms: apiRequest.data,
      });
    }
  }
};
