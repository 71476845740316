import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
export const ZIP_SDOH = "ZIP_SDOH";
export const ZIP_SDOH_SUCCESS = "ZIP_SDOH_SUCCESS";
export const ZIP_SDOH_FAILURE = "ZIP_SDOH_FAILURE";

export const ZipSdohAction = (payload) => async (dispatch) => {
  dispatch({
    type: ZIP_SDOH,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/sdoh`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    ZIP_SDOH_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: ZIP_SDOH_SUCCESS, apiResponse: apiRequest.data });
  }
};
