import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const SVI = "SVI";
export const SVI_SUCCESS = "SVI_SUCCESS";
export const SVI_FAILURE = "SVIFAILURE";

export const SviAction = (payload) => async (dispatch) => {
  dispatch({
    type: SVI,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/svi`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
      SVI_FAILURE
  );
  if (!apiRequest.error) {
    if (payload.zipcode) {
      dispatch({ type: SVI_SUCCESS, zipSvi: apiRequest.data });
    } else if (payload.county) {
      dispatch({
        type: SVI_SUCCESS,
        countySvi: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: SVI_SUCCESS,
        stateSvi: apiRequest.data,
      });
    }
  }
};
