import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const CUSTOM_FILTER = "CUSTOM_FILTER";
export const CUSTOM_FILTER_SUCCESS = "CUSTOM_FILTER_SUCCESS";
export const CUSTOM_FILTER_FAILURE = "CUSTOM_FILTER_FAILURE";

export const CustomFilterAction = (payload) => async (dispatch) => {
  dispatch({
    type: CUSTOM_FILTER,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/custom-filters`,
    {
      method: "POST",
      body: payload,
    },
    dispatch,
    CUSTOM_FILTER_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({
      type: CUSTOM_FILTER_SUCCESS,
      response: apiRequest.data,
    });
  }
};
