import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const HEALTH = "HEALTH";
export const HEALTH_SUCCESS = "HEALTH_SUCCESS";
export const HEALTH_FAILURE = "HEALTH_FAILURE";

export const HealthAction = (payload) => async (dispatch) => {
  dispatch({
    type: HEALTH,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/health`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    HEALTH_FAILURE
  );
  if (!apiRequest.error) {

    if (payload.zipcode) {
      dispatch({ type: HEALTH_SUCCESS, zipHealth: apiRequest.data });
    } else if (payload.county) {
      dispatch({
        type: HEALTH_SUCCESS,
        countyHealth: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: HEALTH_SUCCESS,
        stateHealth: apiRequest.data,
      });
    }
  }
};
