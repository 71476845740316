import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateSocioe: [],
  countySocioe: [],
  zipSocioe: [],
  apiErrors: [],
  error: false,
};

const SocioeconomicReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SOCIOECONOMIC:
      return {
        ...state,
        isLoading: true,
        stateSocioe: [],
        countySocioe: [],
        zipSocioe: [],
      };

    case ActionTypes.SOCIOECONOMIC_SUCCESS:
      if (action.stateSocioe) {
        return {
          ...state,
          isLoading: false,
          stateSocioe: action.stateSocioe,
          apiErrors: null,
        };
      } else if (action.countySocioe) {
        return {
          ...state,
          isLoading: false,
          countySocioe: action.countySocioe,
          apiErrors: null,
        };
      } else if (action.zipSocioe) {
        return {
          ...state,
          isLoading: false,
          zipSocioe: action.zipSocioe,
          apiErrors: null,
        };
      }

    case ActionTypes.SOCIOECONOMIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        stateSocioe: null,
        countySocioe: null,
        zipSocioe: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default SocioeconomicReducer;
