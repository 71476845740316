import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
import { getPercentage } from "../../utils";

export const STATE_RACE = "STATE_RACE";
export const STATE_RACE_SUCCESS = "STATE_RACE_SUCCESS";
export const STATE_RACE_FAILURE = "STATE_RACE_FAILURE";

export const StateRaceAction = (payload) => async (dispatch) => {
  dispatch({
    type: STATE_RACE,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/race`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    STATE_RACE_FAILURE
  );
  if (!apiRequest.error) {
    // const total =
    //   Object.values(apiRequest.data.result).reduce((a, b) => a + b) -
    //   apiRequest.data.result["POPULATION"];
    delete apiRequest.data.result["State"];

    Object.entries(apiRequest.data.result).map(([key, value]) => {
      return (apiRequest["data"]["result"][key] = value * 100);
    });
    apiRequest.data.result = Object.entries(apiRequest.data.result)
      .sort((a, b) => a[0].localeCompare(b[0]))
         .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    dispatch({ type: STATE_RACE_SUCCESS, apiResponse: apiRequest.data });
  }
};
