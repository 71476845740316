import { ManualAuthApiCall } from "../middlewares/apiMiddleware";
export const ANTHEM_PROGRAMS = "ANTHEM_PROGRAMS";
export const ANTHEM_PROGRAMS_SUCCESS = "ANTHEM_PROGRAMS_SUCCESS";
export const ANTHEM_PROGRAMS_FAILURE = "ANTHEM_PROGRAMS_FAILURE";

export const AnthemProgramsAction = (payload) => async (dispatch) => {
  dispatch({
    type: ANTHEM_PROGRAMS,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/partners?state=All`,
    {
      method: "GET",
    },
    dispatch,
      ANTHEM_PROGRAMS_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: ANTHEM_PROGRAMS_SUCCESS, apiResponse: apiRequest.data });
  }
};
