import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const COMPARE_GENERIC = "COMPARE_GENERIC";
export const COMPARE_GENERIC_SUCCESS = "COMPARE_GENERIC_SUCCESS";
export const COMPARE_GENERIC_FAILURE = "COMPARE_GENERIC_FAILURE";

export const CompareGenericAction =
  (payload, compareType, ...rest) =>
  async (dispatch) => {
    dispatch({
      type: COMPARE_GENERIC,
    });
    let allApis = [];
    await Promise.all(
      ["food", "health", "education", "housing", "socioeconomic", "race", "transportation", "svi"].map(async (x) => {
        const { data } = await ManualAuthApiCall(
          `Anthem/${x}`,
          {
            method: "GET",
            body: payload,
          },
          dispatch,
          COMPARE_GENERIC_FAILURE
        );
        allApis[x] = data;
      })
    );

    dispatch({
      type: COMPARE_GENERIC_SUCCESS,
      response: allApis,
      compareType: compareType,
      rest: compareType == "zip" && rest,
    });
  };
