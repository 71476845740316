import { ManualAuthApiCall } from "../middlewares/apiMiddleware";
const customId = "custom-id-yes";
export const SOCIOECONOMIC = "SOCIOECONOMIC";
export const SOCIOECONOMIC_SUCCESS = "SOCIOECONOMIC_SUCCESS";
export const SOCIOECONOMIC_FAILURE = "SOCIOECONOMIC_FAILURE";

export const SocioecnomicAction = (payload) => async (dispatch) => {
  dispatch({
    type: SOCIOECONOMIC,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/socioeconomic`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    SOCIOECONOMIC_FAILURE
  );
  if (!apiRequest.error) {
    if (payload.zipcode) {
      dispatch({ type: SOCIOECONOMIC_SUCCESS, zipSocioe: apiRequest.data });
    } else if (payload.county) {
      dispatch({
        type: SOCIOECONOMIC_SUCCESS,
        countySocioe: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: SOCIOECONOMIC_SUCCESS,
        stateSocioe: apiRequest.data,
      });
    }
  }
};
