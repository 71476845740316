import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
export const STATE_SDOH = "STATE_SDOH";
export const STATE_SDOH_SUCCESS = "STATE_SDOH_SUCCESS";
export const STATE_SDOH_FAILURE = "STATE_SDOH_FAILURE";

export const StateSdohAction = (payload) => async (dispatch) => {
  dispatch({
    type: STATE_SDOH,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/sdoh`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    STATE_SDOH_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: STATE_SDOH_SUCCESS, apiResponse: apiRequest.data });
  }
};
