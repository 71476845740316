import React, { useRef, useEffect, useState } from "react";
import { connect,useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ActionTypes from "../actions";
import { ToastContainer, toast } from 'react-toastify';
import "./mapbox.css";
import $ from "jquery";
import platform from "platform";
import {
  MapColorAction,
  SviColorAction,
  AnthemProgramsAction,
  StateSdohAction,
  StateRaceAction,
  CountySdohAction,
  CountyRaceAction,
  FilterDataAction,
  ZipRaceAction,
  ZipGgphAction,
  ZipSdohAction,
  HousingAction,
  SocioecnomicAction,
  FoodAction,
  EducationAction,
  SviAction,
  HealthAction,
  TransportationAction,
  CompareGenericAction,
  CustomFilterAction,
  AnthemStateProgramAction,
} from "../actions";
import "ion-rangeslider/css/ion.rangeSlider.min.css";
import "ion-rangeslider/js/ion.rangeSlider.min.js";
import us_state_map from "../json/us_states.json";
import us_county_map from "../json/us_counties_geo_data.json";
import us_state_latlong from "../json/us-states-latlong.json";
import us_county_latlong from "../json/us_county_latlong.json";
import us_cities from "../json/us_cities.json";
import zipcode_latlong from "../json/zip_code_database.json";
import style from "../json/style18.json";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Header from "../header/header";
import Footer from "../footer/footer";
import * as _ from "lodash";
import {
  Col,
  Container,
  Row,
  Nav,
  Tab,
} from "react-bootstrap";

import ToggleSwitch from "../common/ToggleSwitch";
import PreviousIcon from "../assets/images/previousIcon.svg";
import NextIcon from "../assets/images/nextIcon.svg";
import CloseIcon from "../assets/images/close.svg";
import filterData from "../json/filter.json";
import RaceBlock from "../common/RaceBlock";
import MapDataShow from "../common/MapDataShow";
import BreadCrumb from "../common/BreadCrumb";

import SwitchRangeCustomFilter from "../common/SwitchRangeCustomFilter";

const Mapbox = ({
                  mapColor,
                  MapColorAction,
                  sviColor,
                  SviColorAction,
                  anthemPrograms,
                  AnthemProgramsAction,
                  anthemStateProgram,
                  AnthemStateProgramAction,
                  stateRace,
                  StateRaceAction,
                  countyRace,
                  CountyRaceAction,
                  zipRace,
                  ZipRaceAction,
                  housing,
                  HousingAction,
                  socioe,
                  SocioecnomicAction,
                  food,
                  FoodAction,
                  education,
                  EducationAction,
                  svi,
                  SviAction,
                  health,
                  HealthAction,
                  transportation,
                  TransportationAction,
                  compare,
                  CompareGenericAction,
                  customFilterColor,
                  CustomFilterAction,
                }) => {
  const dispatch = useDispatch();


  let defaultCustomFilters = {
    food: {
      food_insecure_people: {
        matrix: "food_insecurity_people",
        from: 0,
        to: 100,
        type: "percentage",
        options: { allowDivision: true },
        tooltip:
            "<p>Estimated percentage of total persons in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>",
      },
      food_insecure_children: {
        matrix: "food_insecurity_child",
        from: 0,
        to: 100,
        type: "percentage",
        options: { allowDivision: true },
        tooltip:
            "<p>Estimated percentage of the child population in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>",
      },
      low_food_access: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percentage of people in a county living more than 1 mile from a supermarket or large grocery store if in an urban area, or more than 10 miles from a supermarket or large grocery store if in a rural area.</p><p>Data Source: USDA</p>",
      },
      low_income_food_access: {
        matrix: "low_income&low_food_access",
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percentage of people in a county with low income and living more than 1 mile from a supermarket or large grocery store if in an urban area, or more than 10 miles from a supermarket or large grocery store if in a rural area. Low-income is defined as annual family income of less than or equal to 200 percent of the Federal poverty threshold based on family size.</p><p>Data Source: USDA</p>",
      },
      grocery_store_rate: {
        from: 0.1,
        to: 1,
        type: "ratio",
        tooltip:
            "<p>Number of supermarkets and grocery stores in the county per 1,000 county residents. Include establishments generally known as supermarkets and smaller grocery stores primarily engaged in retailing a general line of food, such as canned and frozen foods; fresh fruits and vegetables; and fresh and prepared meats, fish, and poultry. Exclude convenience stores, supercenters, and warehouse club stores.</p><p>Data Source: USDA</p>",
      },
    },
    housing: {
      sub__standard_housing: {
        matrix: "% Substandard Housing",
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of without a sink with a faucet, a stove or range, or a refrigerator.</p><p>Data Source: American Community Survey</p>",
      },
      living_alone: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of households that report only 1 person living or staying at the address for more than 2 months; does not include anyone who is living somewhere else for more than 2 months, such as a college student living away or someone in the Armed Forces on deployment.</p><p>Data Source: American Community Survey </p>",
      },
      single__parent_household: {
        matrix: "single_parent_household",
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of households in which there is no spouse present. A family household contains at least 2 people - the householder and at least one other person related to the householder by birth, marriage, or adoption.</p><p>Data Source: American Community Survey</p>",
      },
    },
    socioeconomic: {
      broadband_internet_use: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of households that report using a broadband (high-speed) internet service.</p> <p>Data Source: American Community Survey</p>",
      },
      median_household_income: {
        from: 0,
        to: 300000,
        type: "price",
        tooltip:
            "<p>The 50th percentile of household income for a given community. The median household income refers to an income level where half of households in a community earn more and half earn less.</p><p>Data Source:  American Community Survey</p>",
      },
      speaking_foreign_language: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of population over the age of 5 that report speaking a language other than English at home.</p><p>Data Source: American Community Survey</p>",
      },
      receiving_SNAP: {
        matrix: "receiving_snap",
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of households enrolled in Supplemental Nutritional Assistance Program (SNAP), commonly referred to as the food stamp program.</p><p>Data Source: American Community Survey</p>",
      },
      living_extreme_poverty: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of families with income below 125 percent of the federal poverty level. </p><p>Data Source: American Community Survey</p>",
      },
      social_vulnerability_index: {
        from: 0.1,
        to: 1,
        type: "ratio",
        tooltip:
            "<p>Possible scores range from 0 (lowest vulnerability) to 1 (highest vulnerability). Social vulnerability is a term describing how resilient a community is when confronted by external stresses on human health. These stresses can range from natural or human-caused disasters to disease outbreaks. By reducing social vulnerability, we can decrease both human suffering and economic losses. Data Source: CDC SVI</p>",
      },
      unemployed: {
        matrix: "unemployed_rate",
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of population that do not have a job, have actively looked for work in the past 4 weeks, and are currently available to work.</p><p>Data Source: Department of Labor</p>",
      },
    },
    education: {
      no_high_school_diploma: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of individuals over the age of 25 that have not received a high school diploma, GED, or alternative credential.</p> <p>Data Source: American Community Survey</p>",
      },
    },
    transportation: {
      no_access_to_vehicle: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of households with no automobiles, vans, or trucks kept at the home for use by members of the household.</p><p>Data Source: American Community Survey</p>",
      },
      public_transportation_usage: {
        from: 0,
        to: 100,
        type: "percentage",
        tooltip:
            "<p>Percent of working population that reported getting to work in the prior week primarily via bus, trolley, streetcar, subway, railroad, or ferryboat; does not include taxicab.</p><p>Data Source: American Community Survey</p>",
      },
    },
    health: {},
  };


  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    MapColorAction();
    SviColorAction();
    AnthemProgramsAction();
  }, []);

  const openNav = useRef();
  const openNavFunction = () => {
    openNav.current.classList.add("opened-nav");
  };
  const closeNavFunction = async (resetFilter = false) => {
    openNav.current.classList.remove("opened-nav");
    if (resetFilter == true) {
      dispatch({
        type: ActionTypes.CUSTOM_FILTER,
      });
      setCustomFilters(defaultCustomFilters);
      setChangedCustomFilters([]);
      let sliders = $(".sidenav").find(".js-range-slider");
      $(sliders).each(function (index, slider) {
        let slider_instance = $(slider).data("ionRangeSlider");
        slider_instance.reset();
      });
      if (currentColor == "svi") {
        await getStateMapColor();
        await getCountySviMapColor();
        await changetoSvi();
      }
      if (currentColor != "svi") {
        await getStateMapColor();
        await getCountyMapColor();
        await changetofoodInsecurity();
      }

      if (modalTab == "state" || modalTab == "county" || modalTab == "zip") {
        highlightStateMapNew(getStateName(food.stateFood.result.state), "");
      }
      if (modalTab == "county" || modalTab == "zip") {
        highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);
      }
    }
  };

  const mapContainer = useRef(null);
  let [changedCustomFilters, setChangedCustomFilters] = useState([]);
  let [map, setMap] = useState();
  let [state_map] = useState(null);
  let [state_list] = useState([]);
  let [county_list] = useState([]);
  let [state_id_name] = useState({});
  // MAP AND FILTER STATE
  const [showPopUp, setShowPopUp] = useState(false);
  const [comparePopUp, setComparePopUp] = useState(false);
  const [slide, setSlide] = useState(false);
  const [showSearchPopUp, setSearchShowPopUp] = useState(false);
  const [currentColor, setCurrentColor] = useState("foodInsecurity");
  const [modalTab, setModalTab] = useState("");
  const [mapLatLon, setMapLatLon] = useState({});
  const [filters, setFilters] = useState({ food: true });
  const [filterArray, setFilterArray] = useState([]);
  const [suggestions, setSuggestions] = useState({});
  const [customFilters, setCustomFilters] = useState(defaultCustomFilters);

  const [formFields, setFormFields] = useState({ search: "", compareSearch: "" });
  const [anthemSwitch, setAnthemSwitch] = useState(false);
  const [active, setActive] = useState(-1);

  const inputsHandler = (e) => {
    setFormFields({ ...formFields, [e.name]: e.value });

    if (comparePopUp) {
      if (modalTab == "zip") {
        if (e.value.length > 5) {
          alert("Zip Code Invalid!");
        }
      }
    }
  };
  const onTextChange = (e, inputName) => {
    if (inputName == "search") handleClosePopUps(false, true);
    const value = e.target.value;
    if (value.length) {
      const regex = new RegExp(`^${value}`, `i`);
      let checkAvailablity = filterArray.filter((x) => regex.test(x));
      if (inputName != "search") {
        if (modalTab == "state") {
          checkAvailablity = checkAvailablity.filter((x) => !x.includes(","));
        } else if (modalTab == "county") {
          checkAvailablity = checkAvailablity.filter((x) => x.includes(","));
        }
      }
      if (checkAvailablity != null) {
        setSuggestions({
          ...suggestions,
          [inputName]: {
            checkAvailablity,
            text: value,
          },
        });
        inputsHandler({ name: inputName, value });
      } else {
        inputsHandler({ name: inputName, value: "" });
        setSuggestions({
          ...suggestions,
          [inputName]: {},
        });
      }
    } else {
      inputsHandler({ name: inputName, value: "" });
      setSuggestions({
        ...suggestions,
        [inputName]: {},
      });
    }
  };

  const suggestionSelected = (value, inputName) => {
    inputsHandler({ name: inputName, value });
    setSuggestions({
      ...suggestions,
      [inputName]: {
        checkAvailablity: [],
        text: value,
      },
    });
  };
  useEffect(() => {}, [formFields]);
  const renderSuggestions = (type) => {
    if (!suggestions[type] || !suggestions[type].checkAvailablity || suggestions[type].checkAvailablity.length == 0) {
      return null;
    }

    return (
        <div className="TypeHeadDropDownContent">
          <ul>
            {suggestions &&
            suggestions[type].checkAvailablity.map((x, z) => (
                <li
                    key={z}
                    onClick={(e) => {
                      suggestionSelected(x, type);
                      SearchOnMap(x, false, type == "search" ? false : true);
                    }}
                >
                  {x}
                </li>
            ))}
          </ul>
        </div>
    );
  };
  useEffect(() => {
    let county_list = [];
    let state_list = [];
    filterData.result.forEach((x, i) => {
      let cur_location_json = filterData.result[i]
      let cur_location_tokens = [];

      if ("County" in cur_location_json && !("Hospital" in cur_location_json) && !("City" in cur_location_json)) {
        county_list.push(`${cur_location_json["County"]}, ${cur_location_json["State"]}`);
      } else if ("State Name" in cur_location_json && !("County" in cur_location_json)) {
        cur_location_tokens.push(cur_location_json["State Name"]);
        let cur_location = cur_location_tokens.join(",");
        state_list.push(cur_location);
      }
    });
    let location_list = [...new Set(state_list)];
    location_list = location_list.concat([...new Set(county_list)]);
    location_list.push("United States");
    setFilterArray(location_list);
  }, [filterData]);

  useEffect(() => {
  }, [filterArray]);
  useEffect(() => {
    if (!_.isEmpty(anthemStateProgram.statePrograms.result)) {
      let bindAnthemPrograms = [];
      let state = anthemStateProgram.statePrograms.result[0].State.trim();
      anthemStateProgram.statePrograms.result.forEach((x) => {
        let county;
        if (_.isEmpty(x.County)) {
          county = us_cities.Sheet.filter((item) => item.state_id == state && item.city == x.City.trim())[0]
              .county_name;
        } else {
          county = x.County.trim();
        }
        if (bindAnthemPrograms.findIndex((i) => Object.keys(i)[0] == county) == -1) {
          bindAnthemPrograms.push({
            [county]: anthemStateProgram.statePrograms.result.filter((item) => {
              let filterCounty;
              if (_.isEmpty(item.County)) {
                filterCounty = us_cities.Sheet.filter((j) => j.state_id == state && j.city == item.City.trim())[0]
                    .county_name;
              } else {
                filterCounty = item.County.trim();
              }
              if (filterCounty === county) return item;
            }),
          });
        }
      });
      const stateFullName = getStateName(state);
      bindAnthemPrograms.forEach((x, index) => addMarkerToMap(state, Object.keys(x)[0], Object.values(x), index));

      let geo = us_state_latlong.filter((item) => item.state == stateFullName);
      map.flyTo({
        center: [geo[0].longitude, geo[0].latitude],
        zoom: 6.2,
        essential: true,
      });

      // mscip data uploading standard price ready . there are 75 hosplitals and
    }
  }, [anthemStateProgram]);
  let state_name_lower = {
    alabama: "AL",
    alaska: "AK",
    "american samoa": "AS",
    arizona: "AZ",
    arkansas: "AR",
    california: "CA",
    colorado: "CO",
    connecticut: "CT",
    delaware: "DE",
    "federated states of micronesia": "FM",
    florida: "FL",
    georgia: "GA",
    guam: "GU",
    hawaii: "HI",
    idaho: "ID",
    illinois: "IL",
    indiana: "IN",
    iowa: "IA",
    kansas: "KS",
    kentucky: "KY",
    louisiana: "LA",
    maine: "ME",
    "marshall islands": "MH",
    maryland: "MD",
    massachusetts: "MA",
    michigan: "MI",
    minnesota: "MN",
    mississippi: "MS",
    missouri: "MO",
    montana: "MT",
    nebraska: "NE",
    nevada: "NV",
    "new hampshire": "NH",
    "new jersey": "NJ",
    "new mexico": "NM",
    "new york": "NY",
    "north carolina": "NC",
    "north dakota": "ND",
    "northern mariana islands": "MP",
    ohio: "OH",
    oklahoma: "OK",
    oregon: "OR",
    palau: "PW",
    pennsylvania: "PA",
    "puerto rico": "PR",
    "rhode island": "RI",
    "south carolina": "SC",
    "south dakota": "SD",
    tennessee: "TN",
    texas: "TX",
    utah: "UT",
    vermont: "VT",
    "virgin Islands": "VI",
    virginia: "VA",
    washington: "WA",
    "west virginia": "WV",
    wisconsin: "WI",
    wyoming: "WY",
    "district of columbia": "DC",
  };
  let state_name = {
    Alabama: "AL",
    Alaska: "AK",
    "American Samoa": "AS",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "Federated States Of Micronesia": "FM",
    Florida: "FL",
    Georgia: "GA",
    Guam: "GU",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    "Marshall Islands": "MH",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "MP",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Palau: "PW",
    Pennsylvania: "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    "Virgin Islands": "VI",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
    "District of Columbia": "DC",
  };

  // MAP AND FILTER STATE

  const handleClosePopUps = (mapZoomOut = false, force = false) => {
    if (force) {
      setModalTab("");
      setShowPopUp(false);
    }
    handleCloseComparePopUp(force);


    if (mapZoomOut) {
      map.flyTo({
        zoom: 4.0,
        essential: true,
      });
    }
  };
  const handleShow = (type) => setShowPopUp(type);
  const handleShowZip = (e) => {
    setComparePopUp(!comparePopUp);
    comparePopUp == true && handleCloseComparePopUp(true);
    comparePopUp == false && setFormFields({ ...formFields, compareSearch: "" });
  };


  useEffect(() => {
    $(".js-range-slider").ionRangeSlider({
      skin: "big",
      type: "double",
      onFinish: function (data) {
        const inputName = data.input[0].name;
        const inputTitle = data.input[0].attributes.titlename.nodeValue;
        let lowerBound = data.from;
        let upperBound = data.to;
        const checkCustomFilter = Object.values(customFilters).find((x) => x[inputTitle])[inputTitle];
        if (checkCustomFilter.from == lowerBound && checkCustomFilter.to == upperBound) {
          changedCustomFilters = changedCustomFilters.filter((x) => x.metricName != inputName);
          setChangedCustomFilters(changedCustomFilters);
        } else {
          lowerBound = data.input[0].attributes.allowdivision.nodeValue == "true" ? data.from / 100 : data.from;
          upperBound = data.input[0].attributes.allowdivision.nodeValue == "true" ? data.to / 100 : data.to;
          const i = changedCustomFilters.findIndex((_element) => _element.metricName == inputName);
          if (i > -1) {
            changedCustomFilters[i] = { metricName: inputName, lowerBound, upperBound };
          } else {
            changedCustomFilters.push({ metricName: inputName, lowerBound, upperBound });
          }
          setChangedCustomFilters(changedCustomFilters);
        }
      },
      onUpdate: function (data) {
        changedCustomFilters = [];
      },
    });
  }, []);

  // POPUP HANDERS

  const callApiOnPopup = (payloadState, payloadCounty, payloadZip, compareReq) => {
    if (compareReq) {
      if (payloadZip) CompareGenericAction(payloadZip, modalTab, payloadCounty);
      if (payloadCounty && !payloadZip) CompareGenericAction(payloadCounty, modalTab);
      if (!payloadCounty && !payloadZip) CompareGenericAction(payloadState, modalTab);
    } else {
      StateRaceAction(payloadState);
      HousingAction(payloadState);
      SocioecnomicAction(payloadState);
      FoodAction(payloadState);
      HealthAction(payloadState);
      TransportationAction(payloadState);
      if (payloadCounty) {
        SocioecnomicAction(payloadCounty);
        HousingAction(payloadCounty);
        CountyRaceAction(payloadCounty);
        FoodAction(payloadCounty);
        EducationAction(payloadCounty);
        SviAction(payloadCounty);
        TransportationAction(payloadCounty);
      }
      if (payloadZip) {
        ZipRaceAction(payloadZip);
        HousingAction(payloadZip);
        SocioecnomicAction(payloadZip);
        FoodAction(payloadZip);
        EducationAction(payloadZip);
        TransportationAction(payloadZip);
      }
    }
  };
  useEffect(async () => {
    if (mapColor.data && mapColor.data.result) {
      await getStateMapColor();
      await getCountyMapColor();
      state_map = initialMap();
    }
  }, [mapColor]);
  useEffect(async () => {

  }, [sviColor]);

  const initialMap = () => {
    let map;
    mapboxgl.accessToken =
        "pk.eyJ1IjoiemVlc2hhbjUyNyIsImEiOiJja2ViNXZsaGkwM3dyMzBua3B5aDk2Zm9iIn0.LAUWebcZsd7Mqryc_N8Rwg";
    map = new mapboxgl.Map({
      container: mapContainer.current,
      style: style,
      center: [-1.3992113533350334, -1.9811031734521123],
      bearing: 0,
      minZoom: 4,
      zoom: 4,
    });
    let zoomThreshold = 5;
    // pixels the map pans when the up or down arrow is clicked
    const deltaDistance = 100;
    function easing(t) {
      return t * (2 - t);
    }
    // degrees the map rotates when the left or right arrow is clicked
    map.on("style.load", () => {
      if (map.getLayer("counties")) {
        map.removeLayer("counties");
      }
      if (map.getLayer("state-boundaries")) {
        map.removeLayer("state-boundaries");
      }
      if (map.getLayer("county-boundaries")) {
        map.removeLayer("county-boundaries");
      }
      if (map.getLayer("county-points")) {
        map.removeLayer("county-points");
      }
      if (map.getLayer("state-points")) {
        map.removeLayer("state-points");
      }
      map.addSource("state_case", {
        type: "geojson",
        data: us_state_map,
      });
      map.addSource("county_case", {
        type: "geojson",
        data: us_county_map,
      });
      map.addLayer({
        id: "states",
        type: "fill",
        source: "county_case",
        maxzoom: zoomThreshold,
        layout: {},
        paint: {
          "fill-color": {
            property: "risk", // this will be your density property form you geojson
            type: "categorical",
            stops: [
              [-1, "lightgrey"],
              [0, "#EBE4FF"],
              [1, "#794CFF"],
              [2, "#5009B5"],
              [3, "#2B1B49"],
              [4, "#110A1D"],
            ],
          },

        },
      });

      map.addLayer({
        id: "counties",
        type: "fill",
        source: "county_case",
        minzoom: zoomThreshold,
        layout: {},
        paint: {
          "fill-color": {
            property: "risk", // this will be your density property form you geojson
            type: "categorical",
            stops: [
              [-1, "lightgrey"],
              [0, "#EBE4FF"],
              [1, "#794CFF"],
              [2, "#5009B5"],
              [3, "#2B1B49"],
              [4, "#110A1D"],
            ],
          },
        },
      });
      map.addLayer({
        id: "state-boundaries",
        type: "line",
        source: "composite",
        "source-layer": "albersusa",
        filter: ["match", ["get", "type"], ["state"], true, false],
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 2,
        },
      });
      map.addLayer({
        id: "county-boundaries",
        type: "line",
        source: "composite",
        "source-layer": "albersusa",
        filter: ["match", ["get", "type"], ["county"], true, false],
        layout: {},
        paint: { "line-color": "#FFF", "line-width": 0.9 },
      });
      map.addLayer({
        id: "county-points",
        type: "symbol",
        source: "composite",
        "source-layer": "albersusa-points",
        filter: ["match", ["get", "type"], ["county"], true, false],
        layout: {
        },
        paint: {
          "text-halo-width": 1,
          "text-halo-color": "#000000",
          "text-color": "#ffffff",
          "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
        },
      });
      map.addLayer({
        id: "state-points",
        type: "symbol",
        source: "composite",
        "source-layer": "albersusa-points",
        filter: ["match", ["get", "type"], ["state"], true, false],
        layout: {
          "text-field": ["to-string", ["get", "state_abbrev"]],

          "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
        },
        paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
      });

      map.on("click", "states", function (e) {
        let value = $('input[name="anthemDoing"]:checked').val();
        if (value != "on") {
          handleClosePopUps(false, true);
          inputsHandler({ name: "search", value: "" });

          setModalTab("state");
          let promise = new Promise((resolve, reject) => {
            // STATE
            dispatch({
              type: ActionTypes.STATE_RACE,
            });

            // COUNTY
            dispatch({
              type: ActionTypes.COUNTY_RACE,
            });

            // ZIP

            // HOUSING

            dispatch({
              type: ActionTypes.HOUSING,
            });

            // SOCIIOECNOMIC

            dispatch({
              type: ActionTypes.SOCIOECONOMIC,
            });

            // FOOD

            dispatch({
              type: ActionTypes.FOOD,
            });

            // EDUCATION

            dispatch({
              type: ActionTypes.EDUCATION,
            });
            // HEALTH

            dispatch({
              type: ActionTypes.HEALTH,
            });
            // TRANSPORTATION

            dispatch({
              type: ActionTypes.TRANSPORTATION,
            });
            dispatch({
              type: ActionTypes.COMPARE_GENERIC,
            });
          });

          let state_name = getStateById(e.features[0]["properties"]["STATE"]);
          let lng = e.lngLat.lng;
          let lat = e.lngLat.lat;

          setMapLatLon({ lat, lng });

          // CALL TO ACTION FOR OPEN POPUP

          // CALL TO ACTION FOR OPEN POPUP

          // if (window.innerWidth > 768) {
          // CALL TO ACTION FOR OPEN POPUP

          let payload = { state: getStateCode(state_name) };

          callApiOnPopup(payload);


          // CALL TO ACTION FOR OPEN POPUP
          // }
        }
      });

      map.on("mousemove", "counties", function (e) {
        let value = $('input[name="anthemDoing"]:checked').val();
        if (value != "on") {
          let state_name = getStateById(e.features[0]["properties"]["STATE"]);
          highlightHoverMap(state_name, e.features[0]["properties"]["NAME"]);
        }
      });
      map.on("mouseleave", "counties", function (e) {
        if (map.getLayer("counties-hover")) {
          map.removeLayer("counties-hover");
        }
      });
      map.on("click", "counties", function (e) {
        let getRiskValue = e.features[0]["properties"]["risk"];
        if (getRiskValue != -1) {
          let value = $('input[name="anthemDoing"]:checked').val();
          if (value != "on") {
            handleClosePopUps(false, true);
            inputsHandler({ name: "search", value: "" });

            let state_name = getStateById(e.features[0]["properties"]["STATE"]);
            highlightHoverCountyMap(state_name, e.features[0]["properties"]["NAME"]);
            let promise = new Promise((resolve, reject) => {

              dispatch({
                type: ActionTypes.STATE_RACE,
              });


              dispatch({
                type: ActionTypes.COUNTY_RACE,
              });


              // HOUSING

              dispatch({
                type: ActionTypes.HOUSING,
              });

              // SOCIIOECNOMIC

              dispatch({
                type: ActionTypes.SOCIOECONOMIC,
              });

              // FOOD

              dispatch({
                type: ActionTypes.FOOD,
              });

              // EDUCATION

              dispatch({
                type: ActionTypes.EDUCATION,
              });
              // SVI

              dispatch({
                type: ActionTypes.SVI,
              });
              // HEALTH

              dispatch({
                type: ActionTypes.HEALTH,
              });
              // TRANSPORTATION

              dispatch({
                type: ActionTypes.TRANSPORTATION,
              });
              dispatch({
                type: ActionTypes.COMPARE_GENERIC,
              });
            });

            let lng = e.lngLat.lng;
            let lat = e.lngLat.lat;


            setMapLatLon({ lat, lng });

            setModalTab("county");

            // CALL TO ACTION FOR OPEN POPUP
            let payloadState = { state: getStateCode(state_name) };
            let payloadCounty = {
              state: getStateCode(state_name),
              county: e.features[0]["properties"]["NAME"],
            };


            callApiOnPopup(payloadState, payloadCounty);

            // CALL TO ACTION FOR OPEN POPUP
          }
        }
      });
      $(document).on("click", "#mobile-up-control", (e) => {
        e.preventDefault();
        map.panBy([0, -deltaDistance], {
          easing: easing,
        });
      });
      $(document).on("click", "#mobile-bottom-control", (e) => {
        e.preventDefault();
        map.panBy([0, deltaDistance], {
          easing: easing,
        });
      });
      $(document).on("click", "#mobile-left-control", (e) => {
        e.preventDefault();
        map.panBy([-deltaDistance, 0], {
          easing: easing,
        });
      });

      $(document).on("click", "#mobile-right-control", (e) => {
        e.preventDefault();
        map.panBy([deltaDistance, 0], {
          easing: easing,
        });
      });
      setMap(map);
    });
    map.on("zoomend", () => {
      if (map.getZoom() < zoomThreshold) {
        handleClosePopUps(false, true);

        if ($('input[name="foodInsecurity"]:checked').val() == "foodInsecurity") {
          highlightStateMap("", "");
        } else {
          highlightSviStateMap("", "");
        }

        highlightHoverCountyMap("", "");
        let value = $('input[name="anthemDoing"]:checked').val();
        if (value == "on") {
          $(`.state-marker`).removeClass("d-none").addClass("d-block");
          $(`.county-marker`).removeClass("d-block").addClass("d-none");
          $(`.tooltip`).remove();
        }

      }
    });
    map.dragRotate.disable();
    map.keyboard.disable();
    map.doubleClickZoom.disable();
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();
    map.touchPitch.disable();

    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "bottom-left");

    return map;
  };

  const getStateMapColor = async () => {
    for (const entry of us_state_map.features) {
      state_id_name[entry.properties.id] = entry.properties.name;
      let obj = {};
      obj["value"] = entry.properties.name;
      obj["label"] = entry.properties.name;
      state_list.push(obj);
    }
  };
  const getStateSviMapColor = async () => {
    for (const entry of us_state_map.features) {
      state_id_name[entry.properties.id] = entry.properties.name;
      let obj = {};
      obj["value"] = entry.properties.name;
      obj["label"] = entry.properties.name;
      state_list.push(obj);
    }
  };

  const getCountyMapColor = async (customFilter = false, emptyCustomFilterResponse = false) => {
    if (customFilter == false) {
      for (const entry of us_county_map.features) {
        entry.properties["state_name"] = state_id_name[entry.properties.STATE];
        if (!county_list[entry.properties["state_name"]]) {
          county_list[entry.properties["state_name"]] = [];
        }
        let obj = {};
        obj["value"] = entry.properties.NAME;
        obj["label"] = entry.properties.NAME;
        county_list[entry.properties["state_name"]].push(obj);

        let res = getCategoryResult(entry.properties["state_name"], entry.properties.NAME);
        if (res[0] && res[0].county) {
          let riskCategory = parseInt((res[0].food_insecurity_people * 100).toFixed(2));
          if (riskCategory > 0) {
            if (riskCategory >= 4 && riskCategory < 15) {
              entry.properties["risk"] = 0;
            } else if (riskCategory >= 15 && riskCategory < 20) {
              entry.properties["risk"] = 1;
            } else if (riskCategory >= 20 && riskCategory < 25) {
              entry.properties["risk"] = 2;
            } else if (riskCategory >= 25 && riskCategory < 30) {
              entry.properties["risk"] = 3;
            } else if (riskCategory >= 30) {
              entry.properties["risk"] = 4;
            } else {
              entry.properties["risk"] = 0;
            }
          } else {
            entry.properties["risk"] = -1;
          }
        } else {
          entry.properties["risk"] = -1;
        }
      }
    } else {
      for (const entry of us_county_map.features) {
        entry.properties["state_name"] = state_id_name[entry.properties.STATE];
        if (!county_list[entry.properties["state_name"]]) {
          county_list[entry.properties["state_name"]] = [];
        }
        let obj = {};
        obj["value"] = entry.properties.NAME;
        obj["label"] = entry.properties.NAME;
        county_list[entry.properties["state_name"]].push(obj);
        let checkCustomFilter = false;
        if (customFilter && customFilter.length > 0) {
          checkCustomFilter = customFilter.some(
              (x) =>
                  x.state.toLowerCase() == entry.properties["state_name"].toLowerCase() &&
                  x.county.toLowerCase() == entry.properties.NAME.toLowerCase()
          );
        }
        if (checkCustomFilter && emptyCustomFilterResponse == false) {
          let res = getCategoryResult(entry.properties["state_name"], entry.properties.NAME);
          if (res[0] && res[0].county) {
            let riskCategory = parseInt((res[0].food_insecurity_people * 100).toFixed(2));
            if (riskCategory > 0) {
              if (riskCategory >= 4 && riskCategory < 15) {
                entry.properties["risk"] = 0;
              } else if (riskCategory >= 15 && riskCategory < 20) {
                entry.properties["risk"] = 1;
              } else if (riskCategory >= 20 && riskCategory < 25) {
                entry.properties["risk"] = 2;
              } else if (riskCategory >= 25 && riskCategory < 30) {
                entry.properties["risk"] = 3;
              } else if (riskCategory >= 30) {
                entry.properties["risk"] = 4;
              } else {
                entry.properties["risk"] = 0;
              }
            } else {
              entry.properties["risk"] = -1;
            }
          } else {
            entry.properties["risk"] = -1;
          }
        } else {
          entry.properties["risk"] = -1;
        }
      }
    }
  };
  const getCountySviMapColor = async (customFilter = false, emptyCustomFilterResponse = false) => {
    if (customFilter == false) {
      for (const entry of us_county_map.features) {
        entry.properties["state_name"] = state_id_name[entry.properties.STATE];
        if (!county_list[entry.properties["state_name"]]) {
          county_list[entry.properties["state_name"]] = [];
        }
        let obj = {};
        obj["value"] = entry.properties.NAME;
        obj["label"] = entry.properties.NAME;
        county_list[entry.properties["state_name"]].push(obj);
        let res = getSviResult(entry.properties["state_name"], entry.properties.NAME);

        if (res[0] && res[0].county) {
          if (res[0].social_vulnerability_index > 0) {
            if (res[0].social_vulnerability_index > 0 && res[0].social_vulnerability_index < 0.3) {
              entry.properties["risk"] = 0;
            } else if (res[0].social_vulnerability_index >= 0.3 && res[0].social_vulnerability_index < 0.5) {
              entry.properties["risk"] = 1;
            } else if (res[0].social_vulnerability_index >= 0.5 && res[0].social_vulnerability_index < 0.8) {
              entry.properties["risk"] = 2;
            } else if (res[0].social_vulnerability_index > 0.8) {
              entry.properties["risk"] = 3;
            } else {
              entry.properties["risk"] = 0;
            }
          } else {
            entry.properties["risk"] = -1;
          }
        } else {
          entry.properties["risk"] = -1;
        }
      }
    } else {
      for (const entry of us_county_map.features) {
        entry.properties["state_name"] = state_id_name[entry.properties.STATE];
        if (!county_list[entry.properties["state_name"]]) {
          county_list[entry.properties["state_name"]] = [];
        }
        let obj = {};
        obj["value"] = entry.properties.NAME;
        obj["label"] = entry.properties.NAME;
        county_list[entry.properties["state_name"]].push(obj);
        let checkCustomFilter = false;
        if (customFilter && customFilter.length > 0) {
          checkCustomFilter = customFilter.some(
              (x) =>
                  x.state.toLowerCase() == entry.properties["state_name"].toLowerCase() &&
                  x.county.toLowerCase() == entry.properties.NAME.toLowerCase()
          );
        }
        if (checkCustomFilter && emptyCustomFilterResponse == false) {
          let res = getSviResult(entry.properties["state_name"], entry.properties.NAME);
          if (res[0] && res[0].county) {
            if (res[0].social_vulnerability_index > 0) {
              if (res[0].social_vulnerability_index > 0 && res[0].social_vulnerability_index < 0.3) {
                entry.properties["risk"] = 0;
              } else if (res[0].social_vulnerability_index >= 0.3 && res[0].social_vulnerability_index < 0.5) {
                entry.properties["risk"] = 1;
              } else if (res[0].social_vulnerability_index >= 0.5 && res[0].social_vulnerability_index < 0.8) {
                entry.properties["risk"] = 2;
              } else if (res[0].social_vulnerability_index > 0.8) {
                entry.properties["risk"] = 3;
              } else {
                entry.properties["risk"] = 0;
              }
            } else {
              entry.properties["risk"] = -1;
            }
          } else {
            entry.properties["risk"] = -1;
          }
        } else {
          entry.properties["risk"] = -1;
        }
      }
    }
  };


  const getCategoryResult = (state, county) => {
    let stateCode = getStateCode(state);
    let countyName ='';
    if(county =='Virginia Beach City'){
      countyName = 'Virginia Beach';
    }else{
      countyName = county
    }

   let result = mapColor.data.result.filter((item) => item.state === stateCode && item.county === countyName);

    return result;
  };
  const getSviResult = (state, county) => {
    let stateCode = getStateCode(state);


    let result = sviColor.data.result.filter((item) => item.state === stateCode && item.county === county.toLowerCase());

    return result;
  };
  const highlightStateMap = (state, county) => {

    let select_state_map = { type: "FeatureCollection", features: [] };
    let unselect_state_map = { type: "FeatureCollection", features: [] };
    let select_county_map = { type: "FeatureCollection", features: [] };
    let unselect_county_map = { type: "FeatureCollection", features: [] };

    for (const entry of us_state_map.features) {
      if (!state) {
        select_state_map.features.push(entry);
      } else {
        if (entry.properties.name == state) {
          select_state_map.features.push(entry);
        } else {
          unselect_state_map.features.push(entry);
        }
      }
    }

    for (const entry of us_county_map.features) {
      if (!state) {
        select_county_map.features.push(entry);
      } else if (!county) {
        if (state_id_name[entry.properties.STATE] == state) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      } else {
        if (state_id_name[entry.properties.STATE] == state && entry.properties.NAME == county) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      }
    }
    if (state_map) {
      if (state_map.getSource("select_state_case")) {
        state_map.getSource("select_state_case").setData(select_county_map);
      } else {
        state_map.addSource("select_state_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      //county
      if (state_map.getSource("select_county_case")) {
        state_map.getSource("select_county_case").setData(select_county_map);
      } else {
        state_map.addSource("select_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (state_map.getSource("unselect_county_case")) {
        state_map.getSource("unselect_county_case").setData(unselect_county_map);
      } else {
        state_map.addSource("unselect_county_case", {
          type: "geojson",
          data: unselect_county_map,
        });
      }
      if (state_map.getLayer("states")) {
        state_map.removeLayer("states");
      }
      if (state_map.getLayer("states-unselect")) {
        state_map.removeLayer("states-unselect");
      }
    }

    state_map.addLayer({
      id: "states",
      source: "select_state_case",
      maxzoom: 5,

      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });
    if (state_map.getLayer("counties")) {
      state_map.removeLayer("counties");
    }
    if (state_map.getLayer("counties-unselect")) {
      state_map.removeLayer("counties-unselect");
    }
    if (state_map.getLayer("state-boundaries")) {
      state_map.removeLayer("state-boundaries");
    }
    if (state_map.getLayer("county-boundaries")) {
      state_map.removeLayer("county-boundaries");
    }
    if (state_map.getLayer("county-points")) {
      state_map.removeLayer("county-points");
    }
    if (state_map.getLayer("state-points")) {
      state_map.removeLayer("state-points");
    }

    state_map.addLayer({
      id: "counties-unselect",
      source: "unselect_county_case",
      minzoom: 5,
      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 0.3,
      },
    });

    state_map.addLayer({
      id: "counties",
      source: "select_county_case",
      minzoom: 5,

      type: "fill",

      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });

    state_map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    state_map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.5 },
    });
    state_map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {
      },
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    state_map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const highlightSviStateMap = (state, county) => {

    let select_state_map = { type: "FeatureCollection", features: [] };
    let unselect_state_map = { type: "FeatureCollection", features: [] };
    let select_county_map = { type: "FeatureCollection", features: [] };
    let unselect_county_map = { type: "FeatureCollection", features: [] };

    for (const entry of us_state_map.features) {
      if (!state) {
        select_state_map.features.push(entry);
      } else {
        if (entry.properties.name == state) {
          select_state_map.features.push(entry);
        } else {
          unselect_state_map.features.push(entry);
        }
      }
    }

    for (const entry of us_county_map.features) {
      if (!state) {
        select_county_map.features.push(entry);
      } else if (!county) {
        if (state_id_name[entry.properties.STATE] == state) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      } else {
        if (state_id_name[entry.properties.STATE] == state && entry.properties.NAME == county) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      }
    }
    if (state_map) {
      if (state_map.getSource("select_state_case")) {
        state_map.getSource("select_state_case").setData(select_county_map);
      } else {
        state_map.addSource("select_state_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      //county
      if (state_map.getSource("select_county_case")) {
        state_map.getSource("select_county_case").setData(select_county_map);
      } else {
        state_map.addSource("select_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (state_map.getSource("unselect_county_case")) {
        state_map.getSource("unselect_county_case").setData(unselect_county_map);
      } else {
        state_map.addSource("unselect_county_case", {
          type: "geojson",
          data: unselect_county_map,
        });
      }
      if (state_map.getLayer("states")) {
        state_map.removeLayer("states");
      }
      if (state_map.getLayer("states-unselect")) {
        state_map.removeLayer("states-unselect");
      }
    }

    state_map.addLayer({
      id: "states",
      source: "select_state_case",
      maxzoom: 5,

      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });
    if (state_map.getLayer("counties")) {
      state_map.removeLayer("counties");
    }
    if (state_map.getLayer("counties-unselect")) {
      state_map.removeLayer("counties-unselect");
    }
    if (state_map.getLayer("state-boundaries")) {
      state_map.removeLayer("state-boundaries");
    }
    if (state_map.getLayer("county-boundaries")) {
      state_map.removeLayer("county-boundaries");
    }
    if (state_map.getLayer("county-points")) {
      state_map.removeLayer("county-points");
    }
    if (state_map.getLayer("state-points")) {
      state_map.removeLayer("state-points");
    }

    state_map.addLayer({
      id: "counties-unselect",
      source: "unselect_county_case",
      minzoom: 5,
      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 0.3,
      },
    });

    state_map.addLayer({
      id: "counties",
      source: "select_county_case",
      minzoom: 5,

      type: "fill",

      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });

    state_map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    state_map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.5 },
    });
    state_map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {
      },
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    state_map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const getStateById = (id) => {
    let state = us_state_map.features.filter((item) => item.properties.id === id);
    return state[0].properties.name;
  };
  const getStateCode = (state) => {
    return state_name_lower[state.toLowerCase()];
  };

  const getStateName = (code) => {
    return Object.keys(state_name).find((key) => state_name[key] === code);
  };

  const highlightHoverMap = (state, county) => {
    let select_state_map = { type: "FeatureCollection", features: [] };
    let select_county_map = { type: "FeatureCollection", features: [] };
    if (state) {
      for (const entry of us_state_map.features) {
        if (state == entry.properties.name) {
          select_state_map.features.push(entry);
          break;
        }
      }
    }

    if (county) {
      for (const entry of us_county_map.features) {
        if (state == state_id_name[entry.properties.STATE] && county == entry.properties.NAME) {
          select_county_map.features.push(entry);
          break;
        }
      }
    }

    if (state_map) {
      if (state_map.getSource("hover_state_case")) {
        state_map.getSource("hover_state_case").setData(select_state_map);
      } else {
        state_map.addSource("hover_state_case", {
          type: "geojson",
          data: select_state_map,
        });
      }
      if (state_map.getLayer("states-hover")) {
        state_map.removeLayer("states-hover");
      }

      state_map.addLayer({
        id: "states-hover",
        source: "hover_state_case",
        maxzoom: 5,

        type: "fill",
        paint: {
          "fill-color": {
            property: "risk", // this will be your density property form you geojson
            stops: [
              [-1, "lightgrey"],
              [0, "#f9910c"],
              [1, "#f9910c"],
              [2, "#f9910c"],
              [3, "#f9910c"],
              [4, "#f9910c"],
            ],
          },
          "fill-outline-color": "#f9910c",
          "fill-opacity": 0.8,
        },
      });
      if (county) {
        if (state_map.getSource("hover_county_case")) {
          state_map.getSource("hover_county_case").setData(select_county_map);
        } else {
          state_map.addSource("hover_county_case", {
            type: "geojson",
            data: select_county_map,
          });
        }
        if (state_map.getLayer("counties-hover")) {
          state_map.removeLayer("counties-hover");
        }
        state_map.addLayer({
          id: "counties-hover",
          source: "hover_county_case",
          minzoom: 5,
          type: "fill",
          paint: {
            "fill-color": {
              property: "risk", // this will be your density property form you geojson
              stops: [
                [-1, "lightgrey"],
                [0, "#f9910c"],
                [1, "#f9910c"],
                [2, "#f9910c"],
                [3, "#f9910c"],
                [4, "#f9910c"],
              ],
            },
            "fill-outline-color": "#FFF",
            "fill-opacity": 0.5,
          },
        });
      }
    }
  };
  const highlightHoverCountyMap = (state, county) => {
    let select_county_map = { type: "FeatureCollection", features: [] };
    for (const entry of us_county_map.features) {
      if (state == state_id_name[entry.properties.STATE] && county == entry.properties.NAME) {
        select_county_map.features.push(entry);
      }
    }

    if (state_map) {
      if (state_map.getSource("highlight_county_case")) {
        state_map.getSource("highlight_county_case").setData(select_county_map);
      } else {
        state_map.addSource("highlight_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (state_map.getLayer("counties-highlight")) {
        state_map.removeLayer("counties-highlight");
      }

      state_map.addLayer({
        id: "counties-highlight",
        source: "highlight_county_case",
        minzoom: 5,
        type: "fill",
        paint: {
          "fill-color": {
            property: "risk", // this will be your density property form you geojson
            stops: [
              [-1, "lightgrey"],
              [0, "#f9910c"],
              [1, "#f9910c"],
              [2, "#f9910c"],
              [3, "#f9910c"],
              [4, "#f9910c"],
            ],
          },
          "fill-outline-color": "#FFF",
          "fill-opacity": 0.5,
        },
      });
    }
  };
  const highlightCountyMap = (state, countyName) => {
    let countyData = '';
    if(countyName =='virginia beach'){
      countyData = 'Virginia Beach City';
    }else{
      countyData = countyName;
    }
    const arr = countyData.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const county = arr.join(" ");
    let select_county_map = { type: "FeatureCollection", features: [] };
    for (const entry of us_county_map.features) {
      if (state == state_id_name[entry.properties.STATE] && county == entry.properties.NAME) {
        select_county_map.features.push(entry);
      }
    }

    if (map) {
      if (map.getSource("highlight_county_case")) {
        map.getSource("highlight_county_case").setData(select_county_map);
      } else {
        map.addSource("highlight_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (map.getLayer("counties-highlight")) {
        map.removeLayer("counties-highlight");
      }

      map.addLayer({
        id: "counties-highlight",
        source: "highlight_county_case",
        minzoom: 5,
        type: "fill",
        paint: {
          "fill-color": {
            property: "risk", // this will be your density property form you geojson
            stops: [
              [-1, "lightgrey"],
              [0, "#f9910c"],
              [1, "#f9910c"],
              [2, "#f9910c"],
              [3, "#f9910c"],
              [4, "#f9910c"],
            ],
          },
          "fill-outline-color": "#FFF",
          "fill-opacity": 0.5,
        },
      });
    }
  };

  const highlightStateMapNew = (state, county) => {

    let select_state_map = { type: "FeatureCollection", features: [] };
    let unselect_state_map = { type: "FeatureCollection", features: [] };
    let select_county_map = { type: "FeatureCollection", features: [] };
    let unselect_county_map = { type: "FeatureCollection", features: [] };

    for (const entry of us_state_map.features) {
      if (!state) {
        select_state_map.features.push(entry);
      } else {
        if (entry.properties.name == state) {
          select_state_map.features.push(entry);
        } else {
          unselect_state_map.features.push(entry);
        }
      }
    }

    for (const entry of us_county_map.features) {
      if (!state) {
        select_county_map.features.push(entry);
      } else if (!county) {
        if (state_id_name[entry.properties.STATE] == state) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      } else {
        if (state_id_name[entry.properties.STATE] == state && entry.properties.NAME == county) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      }
    }
    if (map) {
      if (map.getSource("select_state_case")) {
        map.getSource("select_state_case").setData(select_county_map);
      } else {
        map.addSource("select_state_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      //county
      if (map.getSource("select_county_case")) {
        map.getSource("select_county_case").setData(select_county_map);
      } else {
        map.addSource("select_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (map.getSource("unselect_county_case")) {
        map.getSource("unselect_county_case").setData(unselect_county_map);
      } else {
        map.addSource("unselect_county_case", {
          type: "geojson",
          data: unselect_county_map,
        });
      }
      if (map.getLayer("states")) {
        map.removeLayer("states");
      }
      if (map.getLayer("states-unselect")) {
        map.removeLayer("states-unselect");
      }
    }

    map.addLayer({
      id: "states",
      source: "select_state_case",
      maxzoom: 5,

      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });
    if (map.getLayer("counties")) {
      map.removeLayer("counties");
    }
    if (map.getLayer("counties-unselect")) {
      map.removeLayer("counties-unselect");
    }
    if (map.getLayer("state-boundaries")) {
      map.removeLayer("state-boundaries");
    }
    if (map.getLayer("county-boundaries")) {
      map.removeLayer("county-boundaries");
    }
    if (map.getLayer("county-points")) {
      map.removeLayer("county-points");
    }
    if (map.getLayer("state-points")) {
      map.removeLayer("state-points");
    }

    map.addLayer({
      id: "counties-unselect",
      source: "unselect_county_case",
      minzoom: 5,
      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 0.3,
      },
    });

    map.addLayer({
      id: "counties",
      source: "select_county_case",
      minzoom: 5,

      type: "fill",

      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });

    map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.5 },
    });
    map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {
      },
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const highlightSviStateMapNew = (state, county) => {

    let select_state_map = { type: "FeatureCollection", features: [] };
    let unselect_state_map = { type: "FeatureCollection", features: [] };
    let select_county_map = { type: "FeatureCollection", features: [] };
    let unselect_county_map = { type: "FeatureCollection", features: [] };

    for (const entry of us_state_map.features) {
      if (!state) {
        select_state_map.features.push(entry);
      } else {
        if (entry.properties.name == state) {
          select_state_map.features.push(entry);
        } else {
          unselect_state_map.features.push(entry);
        }
      }
    }

    for (const entry of us_county_map.features) {
      if (!state) {
        select_county_map.features.push(entry);
      } else if (!county) {
        if (state_id_name[entry.properties.STATE] == state) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      } else {
        if (state_id_name[entry.properties.STATE] == state && entry.properties.NAME == county) {
          select_county_map.features.push(entry);
        } else {
          unselect_county_map.features.push(entry);
        }
      }
    }
    if (map) {
      if (map.getSource("select_state_case")) {
        map.getSource("select_state_case").setData(select_county_map);
      } else {
        map.addSource("select_state_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      //county
      if (map.getSource("select_county_case")) {
        map.getSource("select_county_case").setData(select_county_map);
      } else {
        map.addSource("select_county_case", {
          type: "geojson",
          data: select_county_map,
        });
      }

      if (map.getSource("unselect_county_case")) {
        map.getSource("unselect_county_case").setData(unselect_county_map);
      } else {
        map.addSource("unselect_county_case", {
          type: "geojson",
          data: unselect_county_map,
        });
      }
      if (map.getLayer("states")) {
        map.removeLayer("states");
      }
      if (map.getLayer("states-unselect")) {
        map.removeLayer("states-unselect");
      }
    }

    map.addLayer({
      id: "states",
      source: "select_state_case",
      maxzoom: 5,

      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });
    if (map.getLayer("counties")) {
      map.removeLayer("counties");
    }
    if (map.getLayer("counties-unselect")) {
      map.removeLayer("counties-unselect");
    }
    if (map.getLayer("state-boundaries")) {
      map.removeLayer("state-boundaries");
    }
    if (map.getLayer("county-boundaries")) {
      map.removeLayer("county-boundaries");
    }
    if (map.getLayer("county-points")) {
      map.removeLayer("county-points");
    }
    if (map.getLayer("state-points")) {
      map.removeLayer("state-points");
    }

    map.addLayer({
      id: "counties-unselect",
      source: "unselect_county_case",
      minzoom: 5,
      type: "fill",
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 0.3,
      },
    });

    map.addLayer({
      id: "counties",
      source: "select_county_case",
      minzoom: 5,

      type: "fill",

      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
        "fill-outline-color": "#fff",
        "fill-opacity": 1,
      },
    });

    map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.5 },
    });
    map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {

      },
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const FilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
  };
  const ColorChange = (e) => {
    setCurrentColor(e.target.value);
  };

  useEffect(async () => {

    if (currentColor == "svi") {

      await getStateSviMapColor();
      if (customFilterColor.data.result && customFilterColor.data.result.length != 0) {
        await getCountySviMapColor(customFilterColor.data.result);
      } else {
        await getCountySviMapColor();
      }
      closeNavFunction(true);
      await changetoSvi();

      if (modalTab == "state" || modalTab == "county" || modalTab == "zip") {
        highlightSviStateMapNew(getStateName(food.stateFood.result.state), "");
      }
      if (modalTab == "county" || modalTab == "zip") {
        highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);
      }
    } else {

      if (mapColor.data && mapColor.data.result) {
        await getStateMapColor();
        if (customFilterColor.data.result && customFilterColor.data.result.length != 0) {
          await getCountyMapColor(customFilterColor.data.result);
        } else {
          await getCountyMapColor();
        }
        closeNavFunction(true);
        await changetofoodInsecurity();

        if (modalTab == "state" || modalTab == "county" || modalTab == "zip") {
          highlightStateMapNew(getStateName(food.stateFood.result.state), "");
        }
        if (modalTab == "county" || modalTab == "zip") {
          highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);
        }
      }
    }
  }, [currentColor]);
  const changetoSvi = () => {
    let zoomThreshold = 5;
    if (map.getLayer("states")) {
      map.removeLayer("states");
    }
    if (map.getLayer("counties")) {
      map.removeLayer("counties");
    }
    if (map.getLayer("state-boundaries")) {
      map.removeLayer("state-boundaries");
    }
    if (map.getLayer("county-boundaries")) {
      map.removeLayer("county-boundaries");
    }
    if (map.getLayer("county-points")) {
      map.removeLayer("county-points");
    }
    if (map.getLayer("state-points")) {
      map.removeLayer("state-points");
    }
    map.getSource("state_case").setData(us_state_map);
    map.getSource("county_case").setData(us_county_map);

    map.addLayer({
      id: "states",
      type: "fill",
      source: "county_case",
      maxzoom: zoomThreshold,

      layout: {},
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          type: "categorical",
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
      },
    });

    map.addLayer({
      id: "counties",
      type: "fill",
      source: "county_case",
      minzoom: zoomThreshold,

      layout: {},
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          type: "categorical",
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
          ],
        },
      },
    });
    map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.9 },
    });
    map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const changetofoodInsecurity = () => {
    let zoomThreshold = 5;
    if (map.getLayer("states")) {
      map.removeLayer("states");
    }
    if (map.getLayer("counties")) {
      map.removeLayer("counties");
    }
    if (map.getLayer("state-boundaries")) {
      map.removeLayer("state-boundaries");
    }
    if (map.getLayer("county-boundaries")) {
      map.removeLayer("county-boundaries");
    }
    if (map.getLayer("county-points")) {
      map.removeLayer("county-points");
    }
    if (map.getLayer("state-points")) {
      map.removeLayer("state-points");
    }
    map.getSource("state_case").setData(us_state_map);
    map.getSource("county_case").setData(us_county_map);

    map.addLayer({
      id: "states",
      type: "fill",
      source: "county_case",
      maxzoom: zoomThreshold,
      layout: {},
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          type: "categorical",
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
      },
    });

    map.addLayer({
      id: "counties",
      type: "fill",
      source: "county_case",
      minzoom: zoomThreshold,
      layout: {},
      paint: {
        "fill-color": {
          property: "risk", // this will be your density property form you geojson
          type: "categorical",
          stops: [
            [-1, "lightgrey"],
            [0, "#EBE4FF"],
            [1, "#794CFF"],
            [2, "#5009B5"],
            [3, "#2B1B49"],
            [4, "#110A1D"],
          ],
        },
      },
    });
    map.addLayer({
      id: "state-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
      },
    });
    map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "composite",
      "source-layer": "albersusa",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: { "line-color": "#FFF", "line-width": 0.9 },
    });
    map.addLayer({
      id: "county-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["county"], true, false],
      layout: {},
      paint: {
        "text-halo-width": 1,
        "text-halo-color": "#000000",
        "text-color": "#ffffff",
        "text-opacity": ["step", ["zoom"], 0, 6, 1, 22, 1],
      },
    });
    map.addLayer({
      id: "state-points",
      type: "symbol",
      source: "composite",
      "source-layer": "albersusa-points",
      filter: ["match", ["get", "type"], ["state"], true, false],
      layout: {
        "text-field": ["to-string", ["get", "state_abbrev"]],

        "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
      },
      paint: { "text-halo-width": 1, "text-halo-color": "#ffffff" },
    });
  };
  const handleCloseComparePopUp = (force = false) => {
    setSearchShowPopUp(false);
    setComparePopUp(false);
  };
  const SearchOnMap = (forceVal = false, e = false, compareReq = false) => {
    handleClosePopUps(false, !compareReq);
    if (e) e.preventDefault();
    if (!compareReq) {
      let promise = new Promise((resolve, reject) => {
        let promise = new Promise((resolve, reject) => {
          // STATE
          dispatch({
            type: ActionTypes.STATE_RACE,
          });

          // COUNTY

          dispatch({
            type: ActionTypes.COUNTY_RACE,
          });

          // HOUSING
          dispatch({
            type: ActionTypes.HOUSING,
          });

          // SOCIOECONOMIC
          dispatch({ type: ActionTypes.SOCIOECONOMIC });

          // FOOD

          dispatch({
            type: ActionTypes.FOOD,
          });

          // EDUCATION

          dispatch({
            type: ActionTypes.EDUCATION,
          });
          // HEALTH

          dispatch({
            type: ActionTypes.HEALTH,
          });
          // TRANSPORTATION

          dispatch({
            type: ActionTypes.TRANSPORTATION,
          });

          dispatch({
            type: ActionTypes.COMPARE_GENERIC,
          });
        });
      });
    }

    const search = forceVal ? forceVal : suggestions && suggestions.search && suggestions.search.text;
    if (isNaN(search)) {
      if (search.includes(",")) {
        const splitCounty = search.split(",");
        if (!compareReq) {
          setModalTab("county");
          let geo = us_county_latlong.Sheet.filter(
              (item) => item.state == getStateName(splitCounty[1].trim()) && item.county == splitCounty[0].trim()
          );
          const lat = geo[0].lat;
          const lng = geo[0].long;
          setMapLatLon({ lat, lng });
        }

        const stateCode = splitCounty[1].trim();
        if (!stateCode) {
          alert("You are entering wrong value");
          return false;
        }
        let payloadCounty = {
          state: stateCode,
          county: splitCounty[0],
        };
        let payloadState = { state: stateCode };


        callApiOnPopup(payloadState, payloadCounty, false, compareReq);
      } else {
        const stateCode = getStateCode(search);
        if (!stateCode) {
          alert("You are entering wrong value");
          return false;
        }
        const getSName = getStateName(stateCode);
        let geo = us_state_latlong.filter((item) => item.state == getSName);

        const lat = geo[0].latitude;
        const lng = geo[0].longitude;
        if (!compareReq) {
          setModalTab("state");

          setMapLatLon({ lat, lng });
        }
        let payloadState = { state: stateCode };

        callApiOnPopup(payloadState, false, false, compareReq);
      }
    } else {
      if (search.length > 5) {
        alert("You are entering wrong Zip Code");
        return false;
      }

      let zipInfo = zipcode_latlong.filter((item) => item.zip == search);

      let geoInfo = us_county_latlong.Sheet.filter(
          (item) => item.state == zipInfo[0].state && item.county == zipInfo[0].county
      );

      let lat = geoInfo[0].lat;
      let lng = geoInfo[0].long;
      const stateCode = getStateCode(geoInfo[0].state);

      let payloadCounty = {
        state: stateCode,
        county: geoInfo[0].county,
      };
      let payloadState = { state: stateCode };
      let payloadZip = { zipcode: search };
      if (!compareReq) {
        setModalTab("zip");

        setMapLatLon({ lat, lng });
      }

      callApiOnPopup(payloadState, payloadCounty, payloadZip, compareReq);
    }
  };
  useEffect(() => {


    if (

        stateRace &&
        stateRace.data.result &&
        housing &&
        housing.stateHousing.result &&
        socioe &&
        socioe.stateSocioe &&
        socioe.stateSocioe.result &&
        food &&
        food.stateFood &&
        food.stateFood.result &&
        health &&
        health.stateHealth &&
        health.stateHealth.result &&
        transportation &&
        transportation.stateTransportation.result
    ) {
      if (modalTab == "county") {

        if (
            countyRace &&
            countyRace.data.result &&
            housing &&
            housing.countyHousing &&
            housing.countyHousing.result &&
            socioe &&
            socioe.countySocioe &&
            socioe.countySocioe.result &&
            food &&
            food.countyFood &&
            food.countyFood.result &&
            education &&
            education.countyEducation &&
            education.countyEducation.result
        ) {
          map &&
          map.flyTo({
            center: [mapLatLon && mapLatLon.lng, mapLatLon && mapLatLon.lat],
            zoom: modalTab == "state" ? 5.0 : 6.2,
            essential: true,
          });

          if (currentColor == "foodInsecurity") {
            highlightStateMapNew(getStateName(food.stateFood.result.state), "");
          } else {
            highlightSviStateMapNew(getStateName(food.stateFood.result.state), "");
          }

          map && highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);
          handleShow("county");
          setSlide(false);
        }
      } else if (modalTab == "zip") {
        map &&
        map.flyTo({
          center: [mapLatLon && mapLatLon.lng, mapLatLon && mapLatLon.lat],
          zoom: modalTab == "state" ? 5.0 : 6.2,
          essential: true,
        });
        if (currentColor == "foodInsecurity") {
          highlightStateMapNew("", "");
        } else {
          highlightSviStateMapNew("", "");
        }
        if (
            countyRace &&
            countyRace.data.result &&
            zipRace &&
            zipRace.data.result &&
            housing &&
            housing.zipHousing &&
            housing.zipHousing.result &&
            socioe &&
            socioe.zipSocioe &&
            socioe.zipSocioe.result &&
            food &&
            food.zipFood &&
            food.zipFood.result &&
            education &&
            education.zipEducation.result
        ) {
          map && highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);

          handleShow("zip");
          setSlide(false);
        }
      } else if (modalTab == "state") {
        map &&
        map.flyTo({
          center: [mapLatLon && mapLatLon.lng, mapLatLon && mapLatLon.lat],
          zoom: modalTab == "state" ? 5.0 : 6.2,
          essential: true,
        });

        highlightCountyMap("", "");
        if (currentColor == "foodInsecurity") {
          highlightStateMapNew(getStateName(food.stateFood.result.state), "");
        } else {
          highlightSviStateMapNew(getStateName(food.stateFood.result.state), "");
        }
        handleShow("state");
        setSlide(false);
      }
    }
  }, [stateRace, countyRace, zipRace, housing, socioe, education, svi, health, transportation]);

  useEffect(() => {
  }, [comparePopUp]);
  useEffect(() => {
    if (compare.stateData.food || compare.countyData.food || compare.zipData.food) {
      setComparePopUp(true);
      setSearchShowPopUp(true);
    } else {
      handleCloseComparePopUp();
    }
  }, [compare]);



  const submitCustomFilters = () => {
    if (changedCustomFilters.length > 0) {
      CustomFilterAction(changedCustomFilters);
      closeNavFunction();
    } else {
      closeNavFunction(true);
    }
  };


  const anthemDoingToggle = async () => {

    if (anthemSwitch == true) {
      $(`.county-marker`).removeClass("d-block").addClass("d-none");
      $(`.state-marker`).removeClass("d-block").addClass("d-none");
      $(".tooltip").remove();
    } else {
      $(`.state-marker`).removeClass("d-none").addClass("d-block");

      let bindAnthemPrograms = [];
      anthemPrograms.data.result.forEach((x) => {
        if (bindAnthemPrograms.findIndex((i) => Object.keys(i)[0] == x.State.trim()) == -1) {
          bindAnthemPrograms.push({
            [x.State.trim()]: anthemPrograms.data.result.filter((item) => item.State.trim() === x.State.trim()),
          });
        }
      });
      bindAnthemPrograms.forEach((x, index) => {
        addMarkerToMap(Object.keys(x)[0], false, Object.values(x), index);

      });
    }
    handleClosePopUps(false, true);
    map &&
    map.flyTo({
      zoom: 4.0,
      center: [-1.3992113533350334, -1.9811031734521123],
      essential: true,
    });
    setAnthemSwitch(!anthemSwitch);
  };

  const addMarkerToMap = (state, county, data, index) => {
    let marker='';
      const stateFullName = getStateName(state.trim());
      let lat;
      let lng;
      if (!county) {
        let geo = us_state_latlong.filter((item) => item.state == stateFullName);
        lat = geo[0].latitude;
        lng = geo[0].longitude;
      } else {
        let geo = us_county_latlong.Sheet.filter((item) => item.state == stateFullName && item.county == county.trim());
        lat = geo[0].lat;
        lng = geo[0].long;
      }
      setTimeout(() => $('[data-toggle="tooltip"]').tooltip(), 20);

      let el = document.createElement("div");
      el.innerHTML = `<div class=\"${
          county ? "county" : "state"
      }-marker\"><img src=\"img/anthem-icon.png\" style=\'width:60px;height:60px;\' data-toggle="tooltip" data-trigger="click" data-html="true"  data-placement="top"
      title='<div class="mb-3"></div><ul>${data[0]
          .map((x) => {
            return `<li>${x["Anthem Organization"]} partners with <strong>${
                x["Link"] != ""
                    ? `<a href="${x["Link"]}" target="_blank"> ${x["Partner Organization"].replace(/'/g, "")}</a>`
                    : x["Partner Organization"].replace(/'/g, "")
            }</strong> to address ${x["Focus"]}</li>`;
          })
          .join(" ")}</ul>'/> <div>`;
      let tapped = false;
      if (window.innerWidth > 768) {
        el.addEventListener("click", (e) => {
          const $tooltip = $('[data-toggle="tooltip"]').tooltip();
          $tooltip.on("show.bs.tooltip", () => {
            $(".tooltip").not(this).remove();
          });
        });
        el.addEventListener("dblclick", (e) => {
          if (!county) {
            let payload = { state: state.trim() };
            AnthemStateProgramAction(payload);
            highlightMarkerCounty(state);
            setTimeout(function () {
              const stateFullName = getStateName(state);
              let geo = us_state_latlong.filter((item) => item.state == stateFullName);
              map.flyTo({
                center: [geo[0].longitude, geo[0].latitude],
                zoom: 6.2,
                essential: true,
              });
            }, 1000);
          }
        });
      } else {
        el.addEventListener("touchstart", (e) => {
          if (!tapped) {
            tapped = setTimeout(function () {
              tapped = null;
            }, 300); //wait 300ms
          } else {
            clearTimeout(tapped);
            tapped = null;
            let payload = { state: state.trim() };
            AnthemStateProgramAction(payload);
            highlightMarkerCounty(state, true);
          }
        });
        if (checkDCIo()) {
          el.addEventListener("click", (e) => {
            if (!tapped) {
              tapped = setTimeout(function () {
                tapped = null;
              }, 300); //wait 300ms
            } else {
              clearTimeout(tapped);
              tapped = null;
              let payload = { state: state.trim() };
              AnthemStateProgramAction(payload);
              highlightMarkerCounty(state, true);
            }
          });
        }
      }

      el.addEventListener("mousemove", () => {

      });
      el.addEventListener("mouseenter", () => {

      });
      el.addEventListener("mouseleave", () => {

      });
      // Add markers to the map.

     marker = new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map);


  };

  const checkDCIo = () => {


    // Windows Phone must come first because its UA also contains "Android"
    if (/Chrome/i.test(platform.name) && /ios/i.test(platform.os)) {
      return true;
    } else {
      return false;
    }

  };

  const highlightMarkerCounty = (state, mobileReq = false) => {
    const stateFullName = getStateName(state);
    $(`.county-marker`).remove();
    if (currentColor == "foodInsecurity") {
      highlightStateMapNew(stateFullName, "");
    } else {
      highlightSviStateMapNew(stateFullName, "");
    }

    $(`.county-marker`).removeClass("d-none").addClass("d-block");
    $(`.state-marker`).removeClass("d-block").addClass("d-none");
    $(`.tooltip`).remove();
  };
  useEffect(async () => {
    if (customFilterColor.data.result && customFilterColor.data.result.length != 0) {
      if (currentColor == "svi") {
        await getStateMapColor();
        await getCountySviMapColor(customFilterColor.data.result);
        await changetoSvi();

        if (modalTab == "state" || modalTab == "county" || modalTab == "zip") {
          highlightSviStateMapNew(getStateName(food.stateFood.result.state), "");
        }
      }
      if (currentColor != "svi") {
        await getStateMapColor();
        await getCountyMapColor(customFilterColor.data.result);
        await changetofoodInsecurity();
        if (modalTab == "state" || modalTab == "county" || modalTab == "zip") {
          highlightStateMapNew(getStateName(food.stateFood.result.state), "");
        }
      }
      if (modalTab == "county" || modalTab == "zip") {
        highlightCountyMap(getStateName(food.countyFood.result.state), food.countyFood.result.county);
      }
    }

    if (customFilterColor.data.result && customFilterColor.data.result.length == 0) {
      if (currentColor == "svi") {
        await getCountySviMapColor(true, true);
        await changetoSvi();
      }
      if (currentColor != "svi") {
        await getCountyMapColor(true, true);
        await changetofoodInsecurity();
      }
    }
  }, [customFilterColor]);
  return (
      <>
        <Header />
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="desktop-lowernav" id="lowernavbar">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="#">
                    <img src="img/filters.svg" alt=""></img>
                  </a>
                </li>
                <li className="nav-item">
                  <form onSubmit={(e) => SearchOnMap(false, e)}>
                    <div className="input-group pt-1 search-input">
                      <div className="TypeAheadDropDown">
                        {/* {suggestions && suggestions.search &&  suggestions.search.text && ( */}
                        <input
                            placeholder="Search by State, County, Zip"
                            value={formFields.search}
                            onChange={(e) => onTextChange(e, "search")}
                            type="text"
                            className="form-control"
                            name="search"
                            autoComplete="off"
                        />
                      </div>

                      <div className="input-group-append">
                        <button
                            type="submit"
                            className="input-group-text"
                            id="search-button"
                            // onClick={(e) => SearchOnMap()}
                        >
                          <img src="img/search.svg" alt=""></img>
                        </button>
                      </div>
                    </div>
                  </form>
                  {renderSuggestions("search")}
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="foodDessert"
                          name="food"
                          defaultChecked={filters && filters.food}
                          onClick={(e) => FilterChange(e)}
                      />
                      Food
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="housing"
                          name="housing"
                          defaultChecked={filters && filters.housing}
                          onClick={(e) => FilterChange(e)}
                      />
                      Housing
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="Socioeconomic"
                          name="socioeconomic"
                          defaultChecked={filters && filters.socioeconomic}
                          onClick={(e) => FilterChange(e)}
                      />
                      Socioeconomic
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="foodDessert"
                          name="race"
                          onChange={(e) => {
                            FilterChange(e);
                            setSlide(false);
                          }}
                      />
                      Race / Ethnicity
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="foodDessert"
                          name="education"
                          defaultChecked={filters && filters.education}
                          onClick={(e) => FilterChange(e)}
                      />
                      Education
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="foodDessert"
                          name="health"
                          defaultChecked={filters && filters.health}
                          onClick={(e) => FilterChange(e)}
                      />
                      Health
                    </label>
                  </div>
                </li>
                <li className="nav-item pt-2">
                  <div className="checkbox">
                    <label>
                      <input
                          type="checkbox"
                          id="foodDessert"
                          name="transportation"
                          defaultChecked={filters && filters.transportation}
                          onClick={(e) => FilterChange(e)}
                      />
                      Transportation
                    </label>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                      className="nav-link custom-filters"
                      style={{ marginTop: "-2px", cursor: "pointer"  }}
                      onClick={openNavFunction}
                  >
                    Custom Filters
                  </a>
                </li>

                <li className="nav-item">
                <span className="toggle-switch">
                  <label className="switch mr-2" style={{ marginTop: "6px", paddingTop: "2px" }}>
                    <input
                        type="checkbox"
                        defaultChecked={anthemSwitch}
                        onChange={(e) => anthemDoingToggle()}
                        name="anthemDoing"
                        className="anthemDoing"
                    />
                    <span className="slider round"></span>
                  </label>
                  See what Elevance Health is doing to help
                </span>
                </li>
              </ul>
            </div>
            <div className="mobile-lowernav">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <button className="btn btn-filter text-left h-100" onClick={openNavFunction}>
                      Enter <br></br> Search Criteria
                    </button>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="mobileMap">
                      {modalTab == "state" || modalTab == "county" || modalTab == "zip" ? (
                          <BreadCrumb
                              handleClosePopUps={handleClosePopUps}
                              map={map}
                              SearchOnMap={SearchOnMap}
                              getStateName={getStateName}
                              food={food}
                              whiteBg={true}
                          />
                      ) : (
                          ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="mySidenav" className="sidenav" ref={openNav}>
              <a style={{ cursor: "pointer" }} className="closebtn" onClick={() => closeNavFunction()}>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </a>

              <div className="sidenav-wrapper">
                <h3 className="sidenav-heading d-lg-none d-sm-block d-md-block">Enter Search Criteria</h3>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <h3 className="sidenav-heading d-sm-none d-md-none d-lg-block d-none mt-2 ">Custom Filter</h3>
                  </div>
                  <div className="col-md-5">
                    <a style={{ cursor: "pointer" }} className="btn btn-clear" onClick={() => closeNavFunction(true)}>
                      Clear
                    </a>
                  </div>
                </div>
                <div className="mt-2 mb-4 d-lg-none d-sm-block d-md-block">
                  <p className="p-0 m-0">Search by State, County or Zip</p>
                  <form onSubmit={(e) => SearchOnMap(false, e)}>
                    <div className="input-group pt-1 search-input">
                      <div className="TypeAheadDropDown">
                        <input
                            placeholder="Type location here"
                            value={formFields.search}
                            onChange={(e) => onTextChange(e, "search")}
                            type="text"
                            className="form-control"
                            name="search"
                            autoComplete="off"
                        />
                      </div>

                      <div className="input-group-append">
                        <button
                            type="submit"
                            className="input-group-text"
                            id="search-button"
                        >
                          <i className="fa fa-map-marker marker-blue" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  {renderSuggestions("search")}
                </div>
                <div className="d-lg-none d-sm-block d-md-block">
                  <ul className="list-group mobile-filters">
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="foodDessert"
                              name="food"
                              defaultChecked={filters && filters.food}
                              onClick={(e) => FilterChange(e)}
                          />
                          Food
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="housing"
                              name="housing"
                              defaultChecked={filters && filters.housing}
                              onClick={(e) => FilterChange(e)}
                          />
                          Housing
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="Socioeconomic"
                              name="socioeconomic"
                              defaultChecked={filters && filters.socioeconomic}
                              onClick={(e) => FilterChange(e)}
                          />
                          Socioeconomic
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="foodDessert"
                              name="race"
                              onChange={(e) => {
                                FilterChange(e);
                                setSlide(false);
                              }}
                          />
                          Race / Ethnicity
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="foodDessert"
                              name="education"
                              defaultChecked={filters && filters.education}
                              onClick={(e) => FilterChange(e)}
                          />
                          Education
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="foodDessert"
                              name="health"
                              defaultChecked={filters && filters.health}
                              onClick={(e) => FilterChange(e)}
                          />
                          Health
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item border-0 pt-0 pb-0 pl-0">
                      <div className="checkbox">
                        <label>
                          <input
                              type="checkbox"
                              id="foodDessert"
                              name="transportation"
                              defaultChecked={filters && filters.transportation}
                              onClick={(e) => FilterChange(e)}
                          />
                          Transportation
                        </label>
                      </div>
                    </li>
                  </ul>
                  <div className="mobileColorChangeMap mt-4">
                    <div className="form-check sidebar-foodinsecurity">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="foodInsecurityMobile"
                          id="foodInsecurtyPct"
                          defaultChecked={true}
                          onClick={(e) => ColorChange(e)}
                          value="foodInsecurity"
                      />
                      <label className="form-check-label " htmlFor="exampleRadios1">
                        Display Food Insecurity %{" "}
                        <i
                            className="fa fa-question-circle-o"
                            aria-hidden="true"
                            data-toggle="tooltip"
                            data-html="true"
                            data-placement="top"
                            title="<p>Food insecurity describes a household's inability to provide enough food for every person to live an active, healthy life.</p>
                    <p>Data Source: Feeding America</p>"
                        ></i>
                      </label>
                    </div>
                    <div className="form-check sidebar-socialvulnerability">
                      <input
                          className="form-check-input"
                          type="radio"
                          name="foodInsecurityMobile"
                          id="sviPct"
                          onClick={(e) => ColorChange(e)}
                          value="svi"
                      />
                      <label className="form-check-label " htmlFor="exampleRadios1">
                        Display Social Vulnerability Index %{" "}
                        <i
                            className="fa fa-question-circle-o"
                            aria-hidden="true"
                            data-toggle="tooltip"
                            data-html="true"
                            data-placement="top"
                            title="<p>Possible scores range from 0 (lowest vulnerability) to 1 (highest vulnerability). Social vulnerability is a term describing how resilient a community is when confronted by external stresses on human health. These stresses can range from natural or human-caused disasters to disease outbreaks. By reducing social vulnerability, we can decrease both human suffering and economic losses. Data Source: CDC SVI</p>"
                        ></i>
                      </label>
                    </div>
                  </div>
                  <div className="toggle-switch-mobile mt-5">
                    <label className="switch mr-2">
                      <input
                          type="checkbox"
                          defaultChecked={anthemSwitch}
                          onChange={(e) => anthemDoingToggle()}
                          name="anthemDoing"
                          className="anthemDoing"
                      />
                      <span className="slider round"></span>
                    </label>
                    See what Elevance Health is doing to help <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                  </div>
                </div>
                <h3 className="sidenav-subheading d-lg-none d-sm-block d-md-block mt-5">Custom Filter</h3>
                {customFilters &&
                customFilters.food &&
                Object.keys(customFilters).map((key, i) => (
                    <SwitchRangeCustomFilter
                        customFilters={customFilters[key]}
                        setCustomFilters={setCustomFilters}
                        active={active}
                        setActive={setActive}
                        id={i}
                        key={i}
                        title={key}
                        filters={filters}
                        setChangedCustomFilters={setChangedCustomFilters}
                        changedCustomFilters={changedCustomFilters}
                    />
                ))}
                <hr />
                <div className="row mt-5">
                  <div className="col-6 mt-5">
                    <a style={{ cursor: "pointer" }} className="btn btn-clear" onClick={() => closeNavFunction()}>
                      Cancel
                    </a>
                  </div>
                  <div className="col-6 mt-5">
                    <button className="btn btn-apply" type="submit" onClick={(e) => submitCustomFilters()}>
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <Container fluid>
            <Row>
              <Col>
                <div className="mobile-controls-wrapper">
                  {showPopUp && (
                      <div
                          className="d-none d-sm-block MapPopup"
                      >
                        <div className={"MapPopUpHeader"}>
                          <BreadCrumb
                              handleClosePopUps={handleClosePopUps}
                              map={map}
                              SearchOnMap={SearchOnMap}
                              getStateName={getStateName}
                              food={food}
                          />

                          <div className="customCloseIcon" onClick={() => handleClosePopUps(false, true)}>
                            <img src={CloseIcon} alt="close" />
                          </div>
                        </div>
                        <div className="mt-3 MapPopUpBody">
                          <Row>
                            <Col>
                              <Tab.Container defaultActiveKey={showPopUp}>
                                <Row>
                                  <Col sm={12}>
                                    <Row>
                                      <Nav
                                          style={{
                                            textAlign: "center",
                                            marginBottom: "30px",
                                            width: "100%",
                                            paddingTop: "20px",
                                          }}
                                      >
                                        <Col sm={4}>
                                          <Nav.Item>
                                            <Nav.Link
                                                eventKey="state"
                                                onClick={() => {
                                                  setSlide(false);
                                                  setModalTab("state");
                                                  handleCloseComparePopUp(true);
                                                }}
                                            >
                                              State
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Col>
                                        {showPopUp != "state" && (
                                            <Col sm={4}>
                                              <Nav.Item>
                                                <Nav.Link
                                                    eventKey="county"
                                                    onClick={() => {
                                                      setSlide(false);
                                                      setModalTab("county");
                                                      handleCloseComparePopUp(true);
                                                    }}
                                                >
                                                  County
                                                </Nav.Link>
                                              </Nav.Item>
                                            </Col>
                                        )}
                                        {showPopUp == "zip" && (
                                            <Col sm={4}>
                                              <Nav.Item>
                                                <Nav.Link
                                                    eventKey="zip"
                                                    onClick={() => {
                                                      setSlide(false);
                                                      setModalTab("zip");
                                                      handleCloseComparePopUp(true);
                                                    }}
                                                >
                                                  Zip
                                                </Nav.Link>
                                              </Nav.Item>
                                            </Col>
                                        )}
                                      </Nav>
                                    </Row>
                                  </Col>
                                  <Col sm={12}>
                                    <Tab.Content>
                                      {modalTab != "zip" && (
                                          <div>
                                            {filters && filters.race && (
                                                <>
                                                  {slide ? (
                                                      <div className="PopupNavigator previous" onClick={() => setSlide(!slide)}>
                                                        <img
                                                            src={PreviousIcon}
                                                            alt="PreviousIcon"
                                                            style={{
                                                              height: 19,
                                                              width: 18,
                                                            }}
                                                        />
                                                      </div>
                                                  ) : (
                                                      <div className="PopupNavigator next" onClick={() => setSlide(!slide)}>
                                              <span>
                                                <img
                                                    src={NextIcon}
                                                    alt="NextIcon"
                                                    style={{
                                                      height: 19,
                                                      width: 18,
                                                    }}
                                                />
                                              </span>
                                                      </div>
                                                  )}
                                                </>
                                            )}
                                          </div>
                                      )}
                                      <Tab.Pane eventKey="state">
                                        <h4 className="LabelTab">
                                          {getStateName(
                                              food.stateFood && food.stateFood.result && food.stateFood.result.state
                                          )}
                                        </h4>
                                        <Row
                                            className="mt-4"
                                            style={{
                                              marginLeft: "46px",
                                            }}
                                        >
                                          {!slide &&
                                          housing &&
                                          housing.stateHousing &&
                                          housing.stateHousing.result &&
                                          socioe &&
                                          socioe.stateSocioe &&
                                          socioe.stateSocioe.result &&
                                          food &&
                                          food.stateFood &&
                                          food.stateFood.result &&
                                          health &&
                                          health.stateHealth &&
                                          health.stateHealth.result &&
                                          transportation &&
                                          transportation.stateTransportation &&
                                          transportation.stateTransportation.result && (
                                              <>
                                                <MapDataShow
                                                    housing={housing}
                                                    socioe={socioe}
                                                    food={food}
                                                    svi={svi}
                                                    education={education}
                                                    transportation={transportation}
                                                    filters={filters}
                                                    health={health}
                                                    show="state"
                                                />
                                              </>
                                          )}

                                          {slide && (
                                              <>{stateRace && stateRace.data.result && <RaceBlock getRace={stateRace} />}</>
                                          )}
                                        </Row>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey="county">
                                        <h4 className="LabelTab">
                                          {food.countyFood.result && `${food.countyFood.result.county}, `}
                                          {getStateName(
                                              food.stateFood && food.stateFood.result && food.stateFood.result.state
                                          )}
                                        </h4>
                                        <Row
                                            className="mt-4"
                                            style={{
                                              marginLeft: "46px",
                                            }}
                                        >
                                          {!slide &&
                                          housing &&
                                          housing.countyHousing &&
                                          housing.countyHousing.result &&
                                          socioe &&
                                          socioe.countySocioe &&
                                          socioe.countySocioe.result &&
                                          food &&
                                          food.countyFood &&
                                          food.countyFood.result &&
                                          svi &&
                                          svi.countySvi &&
                                          svi.countySvi.result &&
                                          education &&
                                          education.countyEducation &&
                                          education.countyEducation.result &&
                                          transportation &&
                                          transportation.countyTransportation &&
                                          transportation.countyTransportation.result && (
                                              <>
                                                <MapDataShow
                                                    housing={housing}
                                                    socioe={socioe}
                                                    food={food}
                                                    svi={svi}
                                                    education={education}
                                                    transportation={transportation}
                                                    filters={filters}
                                                    health={health}
                                                    show="county"
                                                />
                                              </>
                                          )}
                                          {slide && (
                                              <>
                                                {countyRace && countyRace.data.result && <RaceBlock getRace={countyRace} />}
                                              </>
                                          )}
                                        </Row>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey="zip">
                                        <h4 className="LabelTab">
                                          {food &&
                                          food.zipFood.result &&
                                          `${suggestions && suggestions.search && suggestions.search.text} (`}

                                          {food.countyFood.result && `${food.countyFood.result.county}, `}
                                          {getStateName(
                                              food.stateFood && food.stateFood.result && food.stateFood.result.state
                                          )}
                                          {")"}
                                        </h4>
                                        <Row
                                            className="mt-4"
                                            style={{
                                              marginLeft: "46px",
                                            }}
                                        >
                                          {housing &&
                                          housing.zipHousing &&
                                          housing.zipHousing.result &&
                                          socioe &&
                                          socioe.zipSocioe &&
                                          socioe.zipSocioe.result &&
                                          food &&
                                          food.zipFood &&
                                          food.zipFood.result &&
                                          education &&
                                          education.zipEducation &&
                                          education.zipEducation.result &&
                                          transportation &&
                                          transportation.zipTransportation &&
                                          transportation.zipTransportation.result && (
                                              <>
                                                <MapDataShow
                                                    housing={housing}
                                                    socioe={socioe}
                                                    food={food}
                                                    svi={svi}
                                                    education={education}
                                                    transportation={transportation}
                                                    filters={filters}
                                                    show="zip"
                                                />
                                              </>
                                          )}
                                        </Row>
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            </Col>
                          </Row>
                        </div>
                        <div className="PopUpFooter">
                          <div className="PopUpFooterContent">
                            {comparePopUp && (
                                <ToggleSwitch
                                    name={modalTab}
                                    showPopUps={handleShowZip}
                                    defaultValue={true}
                                    setFormFields={setFormFields}
                                    formFields={formFields}
                                />
                            )}
                            {!comparePopUp && (
                                <ToggleSwitch
                                    name={modalTab}
                                    showPopUps={handleShowZip}
                                    defaultValue={false}
                                    setFormFields={setFormFields}
                                    formFields={formFields}
                                />
                            )}
                          </div>
                        </div>
                      </div>
                  )}
                  {comparePopUp && (
                      <div className="ZipPopUp MapPopUp">
                        <div className="ZipPopUpHeader mb-3">
                          <div className="customCloseIcon" onClick={() => handleCloseComparePopUp(true)}>
                            <img src={CloseIcon} alt="close" />
                          </div>
                        </div>
                        <div className="ZipPopUpBody">
                          <p>Compare With Another {_.upperFirst(modalTab)} </p>
                          <form onSubmit={(e) => SearchOnMap(formFields.compareSearch, false, true)}>
                            <div className="input-group">
                              <div className="TypeAheadDropDown">
                                <input
                                    placeholder={`Search by ${_.upperFirst(modalTab)}`}
                                    value={formFields.compareSearch}
                                    onChange={(e) => onTextChange(e, "compareSearch")}
                                    type="text"
                                    className="form-control"
                                    name="compareSearch"
                                    autoComplete="off"
                                />
                              </div>

                              <div className="input-group-append">
                                <button type="submit" className="input-group-text" id="search-button">
                                  <img src="img/search.svg" alt=""></img>
                                </button>
                              </div>
                            </div>
                          </form>
                          {renderSuggestions("compareSearch")}
                        </div>
                      </div>
                  )}
                  {showSearchPopUp && (
                      <div className=" SearchPopUp MapPopup d-none d-sm-block">
                        <div className="mt-3">
                          <Row className="SearchPopUpHeader">
                            <Col sm={5}>
                              <form onSubmit={(e) => SearchOnMap(formFields.compareSearch, false, true)}>
                                <div className="input-group">
                                  <div className="TypeAheadDropDown">
                                    <input
                                        value={formFields.compareSearch}
                                        onChange={(e) => onTextChange(e, "compareSearch")}
                                        type="text"
                                        className="form-control"
                                        name="compareSearch"
                                    />
                                  </div>

                                  <div className="input-group-append">
                                    <button type="submit" className="input-group-text" id="search-button">
                                      <img src="img/search.svg" alt=""></img>
                                    </button>
                                  </div>
                                </div>
                              </form>
                              {renderSuggestions("compareSearch")}
                            </Col>
                            <Col sm={{ span: 2, offset: 5 }} className="mt-1" onClick={() => handleCloseComparePopUp(true)}>
                              <img
                                  src={CloseIcon}
                                  style={{
                                    height: 24,
                                    width: 32,
                                    float: "right",
                                    cursor: "pointer",
                                  }}
                              />
                            </Col>
                          </Row>
                          <Row className="SearchPopUpBody">
                            <Col sm={12}>
                              {modalTab != "zip" && (
                                  <>
                                    {filters && filters.race && (
                                        <>
                                          {slide ? (
                                              <div className="PopupNavigator previous" onClick={() => setSlide(!slide)}>
                                                <img src={PreviousIcon} alt="PreviousIcon" style={{ height: 19, width: 18 }} />
                                              </div>
                                          ) : (
                                              <div className="PopupNavigator next" onClick={() => setSlide(!slide)}>
                                      <span>
                                        <img src={NextIcon} alt="NextIcon" style={{ height: 19, width: 18 }} />
                                      </span>
                                              </div>
                                          )}
                                        </>
                                    )}
                                  </>
                              )}
                              <h4 className="LabelTab">
                                {compare &&
                                compare.zipData.food &&
                                compare.zipData.food.result &&
                                `${compare.zipData.food.result["POSTL_ZIP_CD"]} ( ${
                                    compare.zipData.food.result.rest[0].county
                                }, ${getStateName(compare.zipData.food.result.rest[0].state)}`}

                                {compare &&
                                compare.countyData.food &&
                                compare.countyData.food.result &&
                                `${
                                    compare &&
                                    compare.countyData.food &&
                                    compare.countyData.food.result["County, State"].replace(" County", "")
                                } `}
                                {getStateName(compare && compare.stateData.food && compare.stateData.food.result.state)}
                                {compare && compare.zipData.food && compare.zipData.food.result && `)`}
                              </h4>
                              <Row
                                  className="mt-4"
                                  style={{
                                    marginLeft: "46px",
                                  }}
                              >
                                {!slide && compare && compare.stateData && compare.stateData.food && (
                                    <>
                                      <MapDataShow
                                          housing={{ stateHousing: compare.stateData.housing }}
                                          socioe={{ stateSocioe: compare.stateData.socioeconomic }}
                                          food={{ stateFood: compare.stateData.food }}
                                          education={{ stateEducation: compare.stateData.education }}
                                          transportation={{ stateTransportation: compare.stateData.transportation }}
                                          health={{ stateHealth: compare.stateData.health }}
                                          filters={filters}
                                          show="state"
                                      />
                                    </>
                                )}

                                {slide && (
                                    <>
                                      {compare &&
                                      compare.stateData &&
                                      compare.stateData.race &&
                                      compare.stateData.race.result && (
                                          <RaceBlock getRace={{ data: compare.stateData.race }} />
                                      )}
                                    </>
                                )}

                                {!slide && compare && compare.countyData && compare.countyData.food && (
                                    <>
                                      <MapDataShow
                                          housing={{ countyHousing: compare.countyData.housing }}
                                          socioe={{ countySocioe: compare.countyData.socioeconomic }}
                                          food={{ countyFood: compare.countyData.food }}
                                          education={{ countyEducation: compare.countyData.education }}
                                          transportation={{ countyTransportation: compare.countyData.transportation }}
                                          health={{ countyHealth: compare.countyData.health }}
                                          svi={{ countySvi: compare.countyData.svi }}
                                          filters={filters}
                                          show="county"
                                      />
                                    </>
                                )}

                                {slide && (
                                    <>
                                      {compare &&
                                      compare.countyData &&
                                      compare.countyData.race &&
                                      compare.countyData.race.result && (
                                          <RaceBlock getRace={{ data: compare.countyData.race }} />
                                      )}
                                    </>
                                )}
                                {!slide && compare && compare.zipData && compare.zipData.food && (
                                    <>
                                      <MapDataShow
                                          housing={{ zipHousing: compare.zipData.housing }}
                                          socioe={{ zipSocioe: compare.zipData.socioeconomic }}
                                          food={{ zipFood: compare.zipData.food }}
                                          education={{ zipEducation: compare.zipData.education }}
                                          transportation={{ zipTransportation: compare.zipData.transportation }}
                                          health={{ zipHealth: compare.zipData.health }}
                                          filters={filters}
                                          show="zip"
                                      />
                                    </>
                                )}

                              </Row>
                            </Col>
                          </Row>
                          <div className="PopUpFooter row">

                          </div>
                        </div>
                      </div>
                  )}
                  <div ref={mapContainer} className="map-container fwc-anim-fadeIn-up" id="map" />

                  <div className="mobile-controls">
                    <img src="img/mobile-pan-up.svg" className="mobile-up-control" id="mobile-up-control" />
                    <img src="img/mobile-pan-right.svg" className="mobile-right-control" id="mobile-right-control" />
                    <img src="img/mobile-pan-down.svg" className="mobile-bottom-control" id="mobile-bottom-control" />
                    <img src="img/mobile-pan-left.svg" className="mobile-left-control" id="mobile-left-control" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 social-vulnerability-legends">
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="foodInsecurity"
                      id="foodInsecurtyPct"
                      defaultChecked={true}
                      onClick={(e) => ColorChange(e)}
                      value="foodInsecurity"
                  />
                  <label
                      className="form-check-label w-100 foodinsecurtypercentageradio"
                      htmlFor="foodinsecurtypercentageradio"
                  >
                    <p className="foodinsecurtybypercentage mb-1">Food Insecurity by %</p>
                    <div className="row">
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">4-14</p>
                        <p className="green-one"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">15-19</p>
                        <p className="green-two"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">20-24</p>
                        <p className="green-three"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">25-29</p>
                        <p className="green-four"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">30+</p>
                        <p className="green-five"></p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-3 social-vulnerability-legends">
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="foodInsecurity"
                      id="sviPct"
                      onClick={(e) => ColorChange(e)}
                      value="svi"
                  />
                  <label className="form-check-label w-100" htmlFor="foodinsecurtypercentageradio">
                    <p className="foodinsecurtybypercentage mb-1">Social Vulnerability Index</p>
                    <div className="row">
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">&lt; .3</p>
                        <p className="vulnerability-one"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">.3-.5</p>
                        <p className="vulnerability-two"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range">.6-.8</p>
                        <p className="vulnerability-three"></p>
                      </div>
                      <div className="col-md-2 px-0 col-2">
                        <p className="range"> > .8</p>
                        <p className="vulnerability-four"></p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-block d-sm-none mt-5">
                {currentColor == "svi" ? (
                    <>
                      <label className="form-check-label w-100   " htmlFor="foodinsecurtypercentageradio">
                        <p className="foodinsecurtybypercentage mb-1">Social Vulnerability Index</p>
                        <div className="row">
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">&lt; .3</p>
                            <p className="vulnerability-one"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">.3-.5</p>
                            <p className="vulnerability-two"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">.6-.8</p>
                            <p className="vulnerability-three"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range"> > .8</p>
                            <p className="vulnerability-four"></p>
                          </div>
                        </div>
                      </label>
                    </>
                ) : (
                    <>
                      <label
                          className="form-check-label w-100 foodinsecurtypercentageradio  "
                          htmlFor="foodinsecurtypercentageradio"
                      >
                        <p className="foodinsecurtybypercentage mb-1">Food Insecurity by %</p>
                        <div className="row">
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">4-14</p>
                            <p className="green-one"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">15-19</p>
                            <p className="green-two"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">20-24</p>
                            <p className="green-three"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">25-29</p>
                            <p className="green-four"></p>
                          </div>
                          <div className="col-md-2 px-0 col-2">
                            <p className="range">30+</p>
                            <p className="green-five"></p>
                          </div>
                        </div>
                      </label>
                    </>
                )}
              </div>
              <div className="col-md-12 mt-4 d-block d-sm-none mt-5">
                {modalTab == "state" &&
                housing &&
                housing.stateHousing &&
                housing.stateHousing.result &&
                socioe &&
                socioe.stateSocioe &&
                socioe.stateSocioe.result &&
                food &&
                food.stateFood &&
                food.stateFood.result &&
                health &&
                health.stateHealth &&
                health.stateHealth.result &&
                transportation &&
                transportation.stateTransportation &&
                transportation.stateTransportation.result &&
                stateRace &&
                stateRace.data.result && (
                    <>
                      <h4>{getStateName(food.stateFood && food.stateFood.result && food.stateFood.result.state)}</h4>
                      <MapDataShow
                          housing={housing}
                          socioe={socioe}
                          food={food}
                          svi={svi}
                          education={education}
                          transportation={transportation}
                          filters={filters}
                          health={health}
                          show="state"
                      />
                      <>{filters && filters.race && <RaceBlock getRace={stateRace} />}</>
                    </>
                )}

                {modalTab == "county" &&
                housing &&
                housing.countyHousing &&
                housing.countyHousing.result &&
                socioe &&
                socioe.countySocioe &&
                socioe.countySocioe.result &&
                food &&
                food.countyFood &&
                food.countyFood.result &&
                svi &&
                svi.countySvi &&
                svi.countySvi.result &&
                education &&
                education.countyEducation &&
                education.countyEducation.result &&
                transportation &&
                transportation.countyTransportation &&
                transportation.countyTransportation.result &&
                countyRace &&
                countyRace.data.result && (
                    <>
                      <h4 className="">
                        {food.countyFood.result && `${food.countyFood.result.county}, `}
                        {getStateName(food.stateFood && food.stateFood.result && food.stateFood.result.state)}
                      </h4>
                      <MapDataShow
                          housing={housing}
                          socioe={socioe}
                          food={food}
                          svi={svi}
                          education={education}
                          transportation={transportation}
                          filters={filters}
                          health={health}
                          show="county"
                      />
                      <>{filters && filters.race && <RaceBlock getRace={countyRace} />}</>
                    </>
                )}

                {modalTab == "zip" &&
                housing &&
                housing.zipHousing &&
                housing.zipHousing.result &&
                socioe &&
                socioe.zipSocioe &&
                socioe.zipSocioe.result &&
                food &&
                food.zipFood &&
                food.zipFood.result &&
                education &&
                education.zipEducation.result &&
                transportation &&
                transportation.zipTransportation &&
                transportation.zipTransportation.result && (
                    <>
                      <h4 className="">
                        {food &&
                        food.zipFood.result &&
                        `${suggestions && suggestions.search && suggestions.search.text} (`}

                        {food.countyFood.result && `${food.countyFood.result.county}, `}
                        {getStateName(food.stateFood && food.stateFood.result && food.stateFood.result.state)}
                        {")"}
                      </h4>
                      <MapDataShow
                          housing={housing}
                          socioe={socioe}
                          food={food}
                          svi={svi}
                          education={education}
                          transportation={transportation}
                          filters={filters}
                          health={health}
                          show="zip"
                      />
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
  );
};

const mapStateToProps = (state) => ({
  mapColor: state.mapColor,
  sviColor: state.sviColor,
  anthemPrograms: state.anthemPrograms,
  anthemStateProgram: state.anthemStateProgram,
  stateSdoh: state.stateSdoh,
  stateRace: state.stateRace,
  countySdoh: state.countySdoh,
  countyRace: state.countyRace,
  filterDataList: state.filterDataList,
  zipRace: state.zipRace,
  zipGgph: state.zipGgph,
  zipSdoh: state.zipSdoh,
  housing: state.housing,
  socioe: state.socioe,
  food: state.food,
  education: state.education,
  svi: state.svi,
  health: state.health,
  transportation: state.transportation,
  compare: state.compare,
  customFilterColor: state.customFilterColor,
});
export default connect(mapStateToProps, {
  MapColorAction,
  SviColorAction,
  AnthemProgramsAction,
  AnthemStateProgramAction,
  StateSdohAction,
  StateRaceAction,
  CountySdohAction,
  CountyRaceAction,
  FilterDataAction,
  ZipRaceAction,
  ZipGgphAction,
  ZipSdohAction,
  HousingAction,
  SocioecnomicAction,
  FoodAction,
  HealthAction,
  EducationAction,
  SviAction,
  TransportationAction,
  CompareGenericAction,
  CustomFilterAction,
})(withRouter(Mapbox));
