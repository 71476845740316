import * as ActionTypes from "../../actions";
import {getDialogActionsUtilityClass} from "@mui/material";

const initialState = {
  isLoading: false,
  data: [],
  apiErrors: [],
  error: false,
};
const StateSDOHReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.STATE_SDOH:
      return { ...state, data: [], isLoading: true };

    case ActionTypes.STATE_SDOH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.apiResponse,
        apiErrors: null,
      };

    case ActionTypes.STATE_SDOH_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default StateSDOHReducer;
