import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateFood: [],
  countyFood: [],
  zipFood: [],
  apiErrors: [],
  error: false,
};

const FoodReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FOOD:
      return {
        ...state,
        isLoading: true,
        stateFood: [],
        countyFood: [],
        zipFood: [],
      };

    case ActionTypes.FOOD_SUCCESS:
      if (action.stateFood) {
        return {
          ...state,
          isLoading: false,
          stateFood: action.stateFood,
          apiErrors: null,
        };
      } else if (action.countyFood) {
        return {
          ...state,
          isLoading: false,
          countyFood: action.countyFood,
          apiErrors: null,
        };
      } else if (action.zipFood) {
        return {
          ...state,
          isLoading: false,
          zipFood: action.zipFood,
          apiErrors: null,
        };
      }

    case ActionTypes.FOOD_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipFood: null,
        stateFood: null,
        countyFood: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default FoodReducer;
