import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  compareType: false,
  apiErrors: [],
  stateData: [],
  countyData: [],
  zipData: [],
  error: false,
  raceCal: [],
};

const CompareGenericReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COMPARE_GENERIC:
      return {
        ...state,
        isLoading: true,
        compareType: false,
        apiErrors: [],
        stateData: [],
        countyData: [],
        zipData: [],
        error: false,
        raceCal: [],
      };

    case ActionTypes.COMPARE_GENERIC_SUCCESS:
      if (action.response.race && action.response.race.result) {
        delete action.response.race.result["State"];
        delete action.response.race.result["County"];

        Object.entries(action.response.race.result).map(([key, value]) => {
          return (action["response"]["race"]["result"][key] = value * 100);
        });
        action.response.race.result = Object.entries(action.response.race.result)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      }
      if (action.compareType == "state") {
        return {
          ...state,
          isLoading: false,
          stateData: action.response,
          apiErrors: null,
        };
      } else if (action.compareType == "county") {
        return {
          ...state,
          isLoading: false,
          countyData: action.response,
          apiErrors: null,
        };
      } else if (action.compareType == "zip") {
        action.response.food.result = { ...action.response.food.result, rest: action.rest };
        return {
          ...state,
          isLoading: false,
          zipData: action.response,
          apiErrors: null,
        };
      }

    default:
      return state;
  }
};

export default CompareGenericReducer;
