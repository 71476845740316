import { ManualAuthApiCall } from "../middlewares/apiMiddleware";
export const MAP_COLOR = "MAP_COLOR";
export const MAP_COLOR_SUCCESS = "MAP_COLOR_SUCCESS";
export const MAP_COLOR_FAILURE = "MAP_COLOR_FAILURE";

export const MapColorAction = (payload) => async (dispatch) => {
  dispatch({
    type: MAP_COLOR,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/food?state=All&county=All`,
    {
      method: "GET",
    },
    dispatch,
    MAP_COLOR_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: MAP_COLOR_SUCCESS, apiResponse: apiRequest.data });
  }
};
