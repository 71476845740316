// import { useHistory } from 'react-router-dom';
import { getConfig } from "../environment";
import { ToastContainer, toast } from 'react-toastify';
import { getToken, checkBrowser } from "../utils";
import qs from "query-string";
import * as _ from "lodash";
import authConfig from "./authConfig";
import publicConfig from "./publicConfig";

let { API_HOST } = getConfig();
const customId = "custom-id-yes";
/**
 *
 * TO CALL THE UN-AUTHENTICATED API CALLS
 */
export const ManualApiCall = async (endpoint, options = false, dispatch, actionType, notification = false) => {
  try {
    const response = await publicConfig({
      url: endpoint,
      method: options.method,
      data:
        options && options.contentType
          ? new URLSearchParams(Object.entries(options.body)).toString()
          : JSON.stringify(options.body),
      headers: {
        "Content-Type": _.get(options, "contentType", "application/json"),
        ...options,
      },
    });

    if (response && response.status == 200) {
      return { data: response.data };
    }
  } catch (error) {
    if (error.request.status == 400 || error.request.status == 403 || error.request.status == 401) {
      dispatch({
        type: actionType,
        apiErrors: [JSON.parse(error.request.response)],
      });
      return { error: true, errorDetail: JSON.parse(error.request.response) };
    } else if (error.request.status == 500) {
    }
  }
};
/**
 *
 * TO CALL THE AUTHENTICATED API CALLS
 */
export const ManualAuthApiCall = async (endpoint, options = false, dispatch, actionType, notification = false) => {
  try {
    const response = await authConfig({
      url: endpoint,
      method: options.method,
      params: options.method == "GET" ? options.body : "",
      data:
        options && options.contentType
          ? new URLSearchParams(Object.entries(options.body)).toString()
          : JSON.stringify(options.body),
      headers: {
        "Content-Type": _.get(options, "contentType", "application/json"),
        ...options.headers,
      },
    });

    if (response && response.status == 200) {
      return { data: response.data };
    }
  } catch (error) {
    if (error.request.status == 400 || error.request.status == 403) {
      dispatch({
        type: actionType,
        apiErrors: [JSON.parse(error.request.response)],
      });
      return { error: true, errorDetail: JSON.parse(error.request.response) };
    } else if(error.request.status == 500 || error.request.status == 502) {
      toast("Oops! The backend has encountered an unknown error.", {
        toastId: customId
      });
      return { error: true, errorDetail: error.request.response };

    }else{

      return { error: true, errorDetail: error.request.response };
    }
  }
};
