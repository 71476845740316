import { ManualAuthApiCall } from "../middlewares/apiMiddleware";
export const FILTER_DATA = "FILTER_DATA";
export const FILTER_DATA_SUCCESS = "FILTER_DATA_SUCCESS";
export const FILTER_DATA_FAILURE = "FILTER_DATA_FAILURE";

export const FilterDataAction = (payload) => async (dispatch) => {
  dispatch({
    type: FILTER_DATA,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/autocomplete/`,
    {
      method: "GET",
    },
    dispatch,
    FILTER_DATA_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: FILTER_DATA_SUCCESS, apiResponse: apiRequest.data });
  }
};
