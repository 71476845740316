// Utils
import production from "./production";

/**
 * @public
 * @static
 * Get current environment type
 * @returns {string}
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
 * @public
 * @static
 * Get configuration based on the current environment
 * @returns {Object}
 */
export function getConfig() {
  return getEnv() === "production" ? production : '';
}

