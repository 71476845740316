import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateHealth: [],
  countyHealth: [],
  zipHealth: [],
  apiErrors: [],
  error: false,
};

const HealthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HEALTH:
      return {
        ...state,
        isLoading: true,
        stateHealth: [],
        countyHealth: [],
        zipHealth: [],
      };

    case ActionTypes.HEALTH_SUCCESS:
      if (action.stateHealth) {
        return {
          ...state,
          isLoading: false,
          stateHealth: action.stateHealth,
          apiErrors: null,
        };
      } else if (action.countyHealth) {
        return {
          ...state,
          isLoading: false,
          countyHealth: action.countyHealth,
          apiErrors: null,
        };
      } else if (action.zipHealth) {
        return {
          ...state,
          isLoading: false,
          zipHealth: action.zipHealth,
          apiErrors: null,
        };
      }

    case ActionTypes.HEALTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipHealth: null,
        stateHealth: null,
        countyHealth: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default HealthReducer;
