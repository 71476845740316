import React, { useEffect } from "react";
import StateBlock from "../StateBlock";
import TooltipIcon from "../../assets/images/TooltipIcon.svg";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Breadcrumb,
  Tabs,
  Tab,
  Nav,
  InputGroup,
  Form,
  Tooltip,
  FormControl,
  FormLabel,
  OverlayTrigger,
} from "react-bootstrap";
const MapDataShow = ({ housing, socioe, food, svi, education, transportation, health, filters, show }) => {
  useEffect(() => {
  }, []);

  return (
    <>
      {show == "zip" && (
        <>
          {filters && filters.food && (
            <>
              <StateBlock
                title="Low Food Access"
                tooltip="<p>Percentage of people in a county living more than 1 mile from a supermarket or large grocery store if in an urban area, or more than 10 miles from a supermarket or large grocery store if in a rural area.</p><p>Data Source: USDA</p>"
                perValue={food.zipFood.result["FOOD_ACS_PCT"]}
              />
            </>
          )}
          {filters && filters.housing && (
            <>
              <StateBlock
                title="Single-Parent Household %"
                tooltip="<p>Percent of households in which there is no spouse present. A family household contains at least 2 people - the householder and at least one other person related to the householder by birth, marriage, or adoption.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.zipHousing.result["SNGL_PARNT_HSHLDR_PCT"]}
              />
              <StateBlock
                title="Living Alone %"
                tooltip="<p>Percent of households that report only 1 person living or staying at the address for more than 2 months; does not include anyone who is living somewhere else for more than 2 months, such as a college student living away or someone in the Armed Forces on deployment.</p><p>Data Source: American Community Survey </p>"
                perValue={housing.zipHousing.result["LIVG_ALONE_PCT"]}
              />
              <StateBlock
                title="Sub-Standard Housing"
                tooltip="<p>Percent of without a sink with a faucet, a stove or range, or a refrigerator.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.zipHousing.result["SUB_STNDRD_HOUSG_PCT"]}
              />
            </>
          )}
          {filters && filters.transportation && (
            <>
              <StateBlock
                title="No Access To Vehicle %"
                tooltip="<p>Percent of households with no automobiles, vans, or trucks kept at the home for use by members of the household.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.zipTransportation.result["NO_ACS_TO_VHCL_PCT"]}
              />
              <StateBlock
                title="Public Transportation Usage %"
                tooltip="<p>Percent of working population that reported getting to work in the prior week primarily via bus, trolley, streetcar, subway, railroad, or ferryboat; does not include taxicab.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.zipTransportation.result["PBLC_TRNSPRTN_PCT"]}
              />
            </>
          )}
          {filters && filters.socioeconomic && (
            <>
              <StateBlock
                title="Broadband Internet Use"
                tooltip="<p>Percent of households that report using a broadband (high-speed) internet service.</p> <p>Data Source: American Community Survey</p>"
                perValue={socioe.zipSocioe.result["BRDBND_INTRNT_USE_PCT"]}
              />

              <StateBlock
                title="Receiving SNAP %"
                tooltip="<p>Percent of households enrolled in Supplemental Nutritional Assistance Program (SNAP), commonly referred to as the food stamp program.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.zipSocioe.result["RCVG_SNAP_PCT"]}
              />

              <StateBlock
                title="Median Household Income"
                tooltip="<p>The 50th percentile of household income for a given community. The median household income refers to an income level where half of households in a community earn more and half earn less.</p><p>Data Source:  American Community Survey</p>"
                exactValue={socioe.zipSocioe.result["MEDIAN_HSHLD_INCM_AMT"]}
              />
              <StateBlock
                title="Speaking Foreign Language"
                tooltip="<p>Percent of population over the age of 5 that report speaking a language other than English at home.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.zipSocioe.result["SPKG_FRGN_LANG_PCT"]}
              />
              <StateBlock
                title="Unemployed %"
                tooltip="<p>Percent of population that do not have a job, have actively looked for work in the past 4 weeks, and are currently available to work.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.zipSocioe.result["UNMPLD_PCT"]}
              />
            </>
          )}
          {filters && filters.education && (
            <>
              <StateBlock
                title="No High School Diploma"
                tooltip="<p>Percent of individuals over the age of 25 that have not received a high school diploma, GED, or alternative credential.</p> <p>Data Source: American Community Survey</p>"
                perValue={education.zipEducation.result["NO_HIGH_SCHL_DPLMA_PCT"]}
              />
            </>
          )}
        </>
      )}
      {show == "county" && (
        <>
          {filters && filters.food && (
            <>
              <StateBlock
                title="Food Insecure People"
                tooltip="<p>Estimated percentage of total persons in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>"
                onlyPer={food.countyFood.result["food_insecurity_people"]}
              />
              <StateBlock
                title="Food Insecure Children"
                tooltip="<p>Estimated percentage of the child population in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>"
                onlyPer={food.countyFood.result["food_insecurity_child"]}
              />
              <StateBlock
                title="Low Food Access"
                tooltip="<p>Percentage of people in a county living more than 1 mile from a supermarket or large grocery store if in an urban area, or more than 10 miles from a supermarket or large grocery store if in a rural area.</p><p>Data Source: USDA</p>"
                perValue={food.countyFood.result["low_food_access"]}
              />
              <StateBlock
                title="Low Income & Food Access"
                tooltip="<p>Percentage of people in a county with low income and living more than 1 mile from a supermarket or large grocery store if in an urban area, or more than 10 miles from a supermarket or large grocery store if in a rural area. Low-income is defined as annual family income of less than or equal to 200 percent of the Federal poverty threshold based on family size.</p><p>Data Source: USDA</p>"
                perValue={food.countyFood.result["low_income&low_food_access"]}
              />
              <StateBlock
                title="Grocery store rate"
                tooltip="<p>Number of supermarkets and grocery stores in the county per 1,000 county residents. Include establishments generally known as supermarkets and smaller grocery stores primarily engaged in retailing a general line of food, such as canned and frozen foods; fresh fruits and vegetables; and fresh and prepared meats, fish, and poultry. Exclude convenience stores, supercenters, and warehouse club stores.</p><p>Data Source: USDA</p>"
                totalValue={food.countyFood.result["grocery_store_rate"]}
              />
            </>
          )}
          {filters && filters.housing && (
            <>
              <StateBlock
                title="Sub-Standard Housing %"
                tooltip="<p>Percent of without a sink with a faucet, a stove or range, or a refrigerator.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.countyHousing.result["% Substandard Housing"]}
              />
              <StateBlock
                title="Living Alone %"
                tooltip="<p>Percent of households that report only 1 person living or staying at the address for more than 2 months; does not include anyone who is living somewhere else for more than 2 months, such as a college student living away or someone in the Armed Forces on deployment.</p><p>Data Source: American Community Survey </p>"
                perValue={housing.countyHousing.result["living_alone"]}
              />
              <StateBlock
                title="Single-Parent Household %"
                tooltip="<p>Percent of households in which there is no spouse present. A family household contains at least 2 people - the householder and at least one other person related to the householder by birth, marriage, or adoption.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.countyHousing.result["single_parent_household"]}
              />
            </>
          )}
          {filters && filters.transportation && (
            <>
              <StateBlock
                title="No Access To Vehicle %"
                tooltip="<p>Percent of households with no automobiles, vans, or trucks kept at the home for use by members of the household.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.countyTransportation.result["no_access_to_vehicle"]}
              />
              <StateBlock
                title="Public Transportation Usage %"
                tooltip="<p>Percent of working population that reported getting to work in the prior week primarily via bus, trolley, streetcar, subway, railroad, or ferryboat; does not include taxicab.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.countyTransportation.result["public_transportation_usage"]}
              />
            </>
          )}
          {filters && filters.socioeconomic && (
            <>
              <StateBlock
                title="Broadband Internet Use"
                tooltip="<p>Percent of households that report using a broadband (high-speed) internet service.</p> <p>Data Source: American Community Survey</p>"
                perValue={socioe.countySocioe.result["broadband_internet_use"]}
              />
              <StateBlock
                title="Median Household Income"
                tooltip="<p>The 50th percentile of household income for a given community. The median household income refers to an income level where half of households in a community earn more and half earn less.</p><p>Data Source:  American Community Survey</p>"
                exactValue={socioe.countySocioe.result["median_household_income"]}
              />
              <StateBlock
                title="Speaking Foreign Language"
                tooltip="<p>Percent of population over the age of 5 that report speaking a language other than English at home.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.countySocioe.result["speaking_foreign_language"]}
              />
              <StateBlock
                title="Receiving SNAP %"
                tooltip="<p>Percent of households enrolled in Supplemental Nutritional Assistance Program (SNAP), commonly referred to as the food stamp program.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.countySocioe.result["receiving_snap"]}
              />
              <StateBlock
                title="Living Extreme Poverty"
                tooltip="<p>Percent of families with income below 125 percent of the federal poverty level. </p><p>Data Source: American Community Survey</p>"
                perValue={socioe.countySocioe.result["living_extreme_poverty"]}
              />
              <StateBlock
                title="Social Vulnerability Index"
                tooltip="<p>Possible scores range from 0 (lowest vulnerability) to 1 (highest vulnerability). Social vulnerability is a term describing how resilient a community is when confronted by external stresses on human health. These stresses can range from natural or human-caused disasters to disease outbreaks. By reducing social vulnerability, we can decrease both human suffering and economic losses. Data Source: CDC SVI</p>"
                totalValue={svi.countySvi.result["social_vulnerability_index"]}
              />
              <StateBlock
                title="Unemployed %"
                tooltip="<p>Percent of population that do not have a job, have actively looked for work in the past 4 weeks, and are currently available to work.</p><p>Data Source: Department of Labor</p>"
                perValue={socioe.countySocioe.result["unemployed_rate"]}
              />
            </>
          )}
          {filters && filters.education && (
            <>
              <StateBlock
                title="No High School Diploma"
                tooltip="<p>Percent of individuals over the age of 25 that have not received a high school diploma, GED, or alternative credential.</p> <p>Data Source: American Community Survey</p>"
                perValue={education.countyEducation.result["no_high_school_diploma"]}
              />
            </>
          )}
        </>
      )}
      {show == "state" && (
        <>
          {filters && filters.food && (
            <>
              <StateBlock
                title="Food Insecure People"
                tooltip="<p>Estimated percentage of total persons in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>"
                onlyPer={food.stateFood.result["food_insecurity_people"]}
              />
              <StateBlock
                title="Food Insecure Children"
                tooltip="<p>Estimated percentage of the child population in food-insecure households in 2021.</p><p>Data Source: Feeding America</p>"
                onlyPer={food.stateFood.result["food_insecurity_child"]}
              />
            </>
          )}

          {filters && filters.housing && (
            <>
              <StateBlock
                title="Single-Parent Household %"
                tooltip="<p>Percent of households in which there is no spouse present. A family household contains at least 2 people - the householder and at least one other person related to the householder by birth, marriage, or adoption.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.stateHousing.result["single_parent_household"]}
              />
              <StateBlock
                title="Sub-Standard Housing %"
                tooltip="<p>Percent of without a sink with a faucet, a stove or range, or a refrigerator.</p><p>Data Source: American Community Survey</p>"
                perValue={housing.stateHousing.result["% Substandard Housing"]}
              />
            </>
          )}
          {filters && filters.transportation && (
            <>
              <StateBlock
                title="No Access To Vehicle %"
                tooltip="<p>Percent of households with no automobiles, vans, or trucks kept at the home for use by members of the household.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.stateTransportation.result["no_access_to_vehicle"]}
              />
              <StateBlock
                title="Public Transportation Usage %"
                tooltip="<p>Percent of working population that reported getting to work in the prior week primarily via bus, trolley, streetcar, subway, railroad, or ferryboat; does not include taxicab.</p><p>Data Source: American Community Survey</p>"
                perValue={transportation.stateTransportation.result["public_transportation_usage"]}
              />
            </>
          )}
          {filters && filters.health && (
            <>
              <StateBlock
                title="History of Depression"
                tooltip="<p>% of the population that has been told by a Healthcare Provider they have a form of depression Source: BRFSS</p>"
                perValue={health.stateHealth.result["history_of_depression"]}
              />
              <StateBlock
                title="History of Diabetes"
                tooltip="<p>% of the population that has been told by a Healthcare Provider they have a diabetes Source: BRFSS</p>"
                perValue={health.stateHealth.result["history_of_diabetes"]}
              />
              <StateBlock
                title="History of Heart Disease"
                tooltip="<p>% of the population that have ever been told by a Healthcare Provider they had angina or coronary heart disease Source: BRFSS</p>"
                perValue={health.stateHealth.result["history_of_heart_disease"]}
              />
              <StateBlock
                title="Pre Term Birth Rate"
                tooltip="<p>Preterm birth rates are calculated as the number of preterm births divided by the number of live births with known gestational age multiplied by 100. Preterm birth is considered gestational age under 37 weeks Source: CDC Reproductive Health</p>"
                perValue={health.stateHealth.result["history_of_premature_birth_rate"]}
              />
              <StateBlock
                title="History of Obesity"
                tooltip="<p>% of the population with a BMI>30 Source: BRFSS</p>"
                perValue={health.stateHealth.result["history_of_obesity"]}
              />
              <StateBlock
                title="History of Asthma"
                tooltip="<p>% of the population who have been told by a Healthcare Provider they currently have asthma Source: BRFSS</p>"
                perValue={health.stateHealth.result["history_of_asthma"]}
              />
            </>
          )}
          {filters && filters.socioeconomic && (
            <>
              <StateBlock
                title="Broadband Internet Use"
                tooltip="<p>Percent of households that report using a broadband (high-speed) internet service.</p> <p>Data Source: American Community Survey</p>"
                perValue={socioe.stateSocioe.result["broadband_internet_use"]}
              />
              <StateBlock
                title="Receiving SNAP %"
                tooltip="<p>Percent of households enrolled in Supplemental Nutritional Assistance Program (SNAP), commonly referred to as the food stamp program.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.stateSocioe.result["receiving_snap"]}
              />
              <StateBlock
                title="Speaking Foreign Language"
                tooltip="<p>Percent of population over the age of 5 that report speaking a language other than English at home.</p><p>Data Source: American Community Survey</p>"
                perValue={socioe.stateSocioe.result["speaking_foreign_language"]}
              />
              <StateBlock
                title="Living Extreme Poverty"
                tooltip="<p>Percent of families with income below 125 percent of the federal poverty level. </p><p>Data Source: American Community Survey</p>"
                perValue={socioe.stateSocioe.result["living_extreme_poverty"]}
              />
              <StateBlock
                title="Unemployed %"
                tooltip="<p>Percent of population that do not have a job, have actively looked for work in the past 4 weeks, and are currently available to work.</p><p>Data Source: Department of Labor</p>"
                perValue={socioe.stateSocioe.result["unemployed_rate"]}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MapDataShow;
