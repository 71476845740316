import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const HOUSING = "HOUSING";
export const HOUSING_SUCCESS = "HOUSING_SUCCESS";
export const HOUSING_FAILURE = "HOUSING_FAILURE";

export const HousingAction = (payload) => async (dispatch) => {
  dispatch({
    type: HOUSING,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/housing`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    HOUSING_FAILURE
  );
  if (!apiRequest.error) {
    if (payload.zipcode) {
      dispatch({ type: HOUSING_SUCCESS, zipHousing: apiRequest.data });
    } else if (payload.county) {
      dispatch({
        type: HOUSING_SUCCESS,
        countyHousing: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: HOUSING_SUCCESS,
        stateHousing: apiRequest.data,
      });
    }
  }
};
