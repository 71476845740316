import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateTransportation: [],
  countyTransportation: [],
  zipTransportation: [],
  apiErrors: [],
  error: false,
};

const TransportationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TRANSPORTATION:
      return {
        ...state,
        isLoading: true,
        stateTransportation: [],
        countyTransportation: [],
        zipTransportation: [],
      };

    case ActionTypes.TRANSPORTATION_SUCCESS:
      if (action.stateTransportation) {
        return {
          ...state,
          isLoading: false,
          stateTransportation: action.stateTransportation,
          apiErrors: null,
        };
      } else if (action.countyTransportation) {
        return {
          ...state,
          isLoading: false,
          countyTransportation: action.countyTransportation,
          apiErrors: null,
        };
      } else if (action.zipTransportation) {
        return {
          ...state,
          isLoading: false,
          zipTransportation: action.zipTransportation,
          apiErrors: null,
        };
      }

    case ActionTypes.TRANSPORTATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipTransportation: null,
        stateTransportation: null,
        countyTransportation: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default TransportationReducer;
