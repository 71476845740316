import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
export const COUNTY_SDOH = "COUNTY_SDOH";
export const COUNTY_SDOH_SUCCESS = "COUNTY_SDOH_SUCCESS";
export const COUNTY_SDOH_FAILURE = "COUNTY_SDOH_FAILURE";

export const CountySdohAction = (payload) => async (dispatch) => {
  dispatch({
    type: COUNTY_SDOH,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/sdoh`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    COUNTY_SDOH_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: COUNTY_SDOH_SUCCESS, apiResponse: apiRequest.data });
  }
};
