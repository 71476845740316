import "./App.css";
import Mapbox from "./mapbox/mapbox";
import Header from "./header/header";
import React from "react";
import Footer from "./footer/footer";
import "mapbox-gl/dist/mapbox-gl.css";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/">
            <Mapbox />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
