import { ManualAuthApiCall } from "../middlewares/apiMiddleware";

export const TRANSPORTATION = "TRANSPORTATION";
export const TRANSPORTATION_SUCCESS = "TRANSPORTATION_SUCCESS";
export const TRANSPORTATION_FAILURE = "TRANSPORTATION_FAILURE";

export const TransportationAction = (payload) => async (dispatch) => {
  dispatch({
    type: TRANSPORTATION,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/transportation`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    TRANSPORTATION_FAILURE
  );
  if (!apiRequest.error) {
    if (payload.zipcode) {
      dispatch({
        type: TRANSPORTATION_SUCCESS,
        zipTransportation: apiRequest.data,
      });
    } else if (payload.county) {
      dispatch({
        type: TRANSPORTATION_SUCCESS,
        countyTransportation: apiRequest.data,
      });
    } else if (payload.state) {
      dispatch({
        type: TRANSPORTATION_SUCCESS,
        stateTransportation: apiRequest.data,
      });
    }
  }
};
