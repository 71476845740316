import React, { useRef, useState, useEffect } from "react";
import { Button, Collapse } from "react-bootstrap";
import * as _ from "lodash";
import $ from "jquery";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import TooltipIcon from "../../assets/images/TooltipIcon.svg";
const SwitchRangeCustomFilter = ({
  customFilters,
  setCustomFilters,
  id,
  active,
  setActive,
  title,
  filters,
  setChangedCustomFilters,
  changedCustomFilters,
}) => {
  const contentSpace = useRef(null);
  const [open, setOpen] = useState(title == "food" ? true : false);

  return (
    <>
      {_.isEmpty(customFilters) ? (
        <div className={`collapsediv  disable-customfilter mb-3 ${filters[title] ? "d-block" : "d-none"}`} key={id}>
          <div role="button" className="collapse-heading-panel">
            <span className="collapse-heading">{title && _.startCase(title.replace("__", "-").replace("_", " "))}</span>
            <i className={`fa fa-plus float-right mt-1`} aria-hidden="true"></i>
          </div>
        </div>
      ) : (
        <div className={`collapsediv mb-3 ${filters[title] ? "d-block" : "d-none"}`} key={id}>
          <div onClick={() => setOpen(!open)} role="button" className="collapse-heading-panel">
            <span className="collapse-heading">{title && _.startCase(title.replace("__", "-").replace("_", " "))}</span>
            <i className={`fa fa-${open ? "minus" : "plus"} float-right mt-1`} aria-hidden="true"></i>
          </div>
          {customFilters && (
            <Collapse in={open} className="collapse-body-panel">
              <div className="row px-2 pt-2">
                {customFilters &&
                  Object.keys(customFilters).map((key, i) => (
                    <div className="col-md-12" key={i}>
                      <span className="food-insecurtity">
                        {_.startCase(key)}{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <div dangerouslySetInnerHTML={{ __html: customFilters[key].tooltip }} />
                            </Tooltip>
                          }
                        >
                          <img src={TooltipIcon} style={{ height: 17, width: 20 }} />
                        </OverlayTrigger>
                      </span>
                      <input
                        type="text"
                        className="js-range-slider"
                        id={`${key}`}
                        name={`${customFilters[key].matrix ? customFilters[key].matrix : key}`}
                        data-min={`${customFilters[key].from}`}
                        data-max={`${customFilters[key].to}`}
                        data-step={customFilters[key].type == "price" ? "100" : "0.1"}
                        data-postfix={customFilters[key].type == "percentage" ? "%" : ""}
                        data-prefix={customFilters[key].type == "price" ? "$" : ""}
                        allowdivision={
                          customFilters[key].options ? `${customFilters[key].options.allowDivision}` : "false"
                        }
                        titlename={key}
                      />
                    </div>
                  ))}
              </div>
            </Collapse>
          )}
        </div>
      )}
    </>
  );
};

export default SwitchRangeCustomFilter;
