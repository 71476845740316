import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  stateSvi: [],
  countySvi: [],
  zipSvi: [],
  apiErrors: [],
  error: false,
};

const SviReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.EDUCATION:
      return {
        ...state,
        isLoading: true,
        stateSvi: [],
        countySvi: [],
        zipSvi: [],
      };

    case ActionTypes.SVI_SUCCESS:
      if (action.stateSvi) {
        return {
          ...state,
          isLoading: false,
          stateSvi: action.stateSvi,
          apiErrors: null,
        };
      } else if (action.countySvi) {
        return {
          ...state,
          isLoading: false,
          countySvi: action.countySvi,
          apiErrors: null,
        };
      } else if (action.zipSvi) {
        return {
          ...state,
          isLoading: false,
          zipSvi: action.zipSvi,
          apiErrors: null,
        };
      }

    case ActionTypes.SVI_FAILURE:
      return {
        ...state,
        isLoading: false,
        zipSvi: null,
        stateSvi: null,
        countySvi: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default SviReducer;
