import { combineReducers } from "redux";

//...................MAP REDUCER.....................//

import MapColorReducer from "./MapColorReducer";
import SviColorReducer from "./SviColorReducer";
import FilterDataReducer from "./FilterDataReducer";

//...................MAP POPUP REDUCER.....................//

// STATE
import StateGGPHReducer from "./popup/StateGGPHReducer";
import StateRaceReducer from "./popup/StateRaceReducer";
import StateSDOHReducer from "./popup/StateSDOHReducer";

// COUNTY
import CountyGGPHReducer from "./popup/CountyGGPHReducer";
import CountyRaceReducer from "./popup/CountyRaceReducer";
import CountySDOHReducer from "./popup/CountySDOHReducer";

// ZIP
import ZipGGPHReducer from "./popup/ZipGGPHReducer";
import ZipRaceReducer from "./popup/ZipRaceReducer";
import ZipSDOHReducer from "./popup/ZipSDOHReducer";

// HOUSING

import HousingReducer from "./HousingReducer";

// SOCIOECNOMIC

import SocioeconomicReducer from "./SocioeconomicReducer";

// FOOD

import FoodReducer from "./FoodReducer";
import HealthReducer from "./HealthReducer";
import AnthemStateProgramReducer from "./AnthemStateProgramReducer";
import TransportationReducer from "./TransportationReducer";
import EducationReducer from "./EducationReducer";
import SviReducer from "./SviReducer";
import AnthemProgramsReducer from "./AnthemProgramsReducer";
import CompareGenericReducer from "./CompareGenericReducer";
import CustomFilterReducer from "./CustomFilterReducer";
import SurveyReducer from "./SurveyReducer";

export default combineReducers({
  mapColor: MapColorReducer,
  sviColor: SviColorReducer,
  filterDataList: FilterDataReducer,
  anthemPrograms: AnthemProgramsReducer,

  //State
  stateGgph: StateGGPHReducer,
  stateRace: StateRaceReducer,
  stateSdoh: StateSDOHReducer,

  // County
  countyGgph: CountyGGPHReducer,
  countyRace: CountyRaceReducer,
  countySdoh: CountySDOHReducer,

  //Zip
  zipGgph: ZipGGPHReducer,
  zipRace: ZipRaceReducer,
  zipSdoh: ZipSDOHReducer,

  // HOUSING
  housing: HousingReducer,

  anthemStateProgram: AnthemStateProgramReducer,

  // SOCIOECNOMIC
  socioe: SocioeconomicReducer,

  // FOOD
  food: FoodReducer,

  // Health
  health: HealthReducer,
  transportation: TransportationReducer,
  education: EducationReducer,
  svi: SviReducer,

  compare: CompareGenericReducer,
  customFilterColor: CustomFilterReducer,

  // Survey Form

  survey: SurveyReducer,
});
