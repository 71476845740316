import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  data: [],
  apiErrors: [],
  error: false,
};

const SviColorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SVI_COLOR:
      return { ...state, isLoading: true };

    case ActionTypes.SVI_COLOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.apiResponse,
        apiErrors: null,
      };

    case ActionTypes.SVI_COLOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default SviColorReducer;
