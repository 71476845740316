// First we need to import axios.js
import axios from "axios";
import moment from "moment";
import { getConfig } from "../environment";
let { API_HOST } = getConfig();
const apiSourceDefault = API_HOST;
const getToken = async () => {};
const makeClient = () => {
  const defaultOptions = {
    baseURL: API_HOST,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(async (config) => {
    let getToken;
    const checkLocalStorage =
      localStorage.getItem("x-auth") == null
        ? null
        : JSON.parse(localStorage.getItem("x-auth"));
    if (
      checkLocalStorage == null ||
      !(
        moment()
          .utc(true)
          .add(2, "minutes")
          .isBefore(moment(checkLocalStorage.expiry).format()) == true
      )
    ) {
      try {
        const loginApiCall = await axios({
          url: `${API_HOST}auth/login`,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (loginApiCall && loginApiCall.data.access_token) {
          localStorage.setItem(
            "x-auth",
            JSON.stringify({
              ...loginApiCall.data,
              expiry: moment().add(30, "minutes").utc(true),
            })
          );
        }
        getToken = JSON.parse(localStorage.getItem("x-auth")).access_token;
      } catch (error) {
        getToken = false;
      }
    } else {
      getToken = JSON.parse(localStorage.getItem("x-auth")).access_token;
    }
    if (!getToken) {
    }
    config.headers.Authorization = getToken ? `Bearer ${getToken}` : "";
    return config;
  });

  return instance;
};
export default makeClient();
