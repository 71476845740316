import * as ActionTypes from "../../actions";

const initialState = {
  isLoading: false,
  data: [],
  apiErrors: [],
  error: false,
};

const ZipRaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ZIP_RACE:
      return { ...state, data: [], isLoading: true };

    case ActionTypes.ZIP_RACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.apiResponse,
        apiErrors: null,
      };

    case ActionTypes.ZIP_RACE_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default ZipRaceReducer;
