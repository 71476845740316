import React from "react"
import "./footer.css"

function Footer (){
    return(
        <footer>
            <div className="container desktop-footer">
                <div className="row">
                    <div className="col-md-6">
                        <p className="mb-0 credits">Developed With Carelon /&nbsp;<a href="https://cloudmedxhealth.com/" target="_blank" rel="noopener noreferrer" className="text-white">CloudMedX</a></p>
                    </div>
                    <div className="col-md-6">
                        <ul className="footer-links">
                            <li>
                                <a href="#" className="credits">Terms of Use</a>
                            </li>
                            <li>
                                <a href="#" className="credits">Protecting Your Privacy</a>
                            </li>
                            <li>
                                <button className="btn btn-wcag"><span><img src="img/double-arrow.png" alt=""/></span>WCAG 2.1 AA</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container mobile-footer">
                <div className="row">
                    <div className="col-md-12">
                        <ul>
                            <li>
                                <a href="#" className="credits termsofuse">Terms of Use</a>
                            </li>
                            <li>
                                <a href="#" className="credits protectingprivacy">Protecting Your Privacy</a>
                            </li>
                            <li>
                                <p className="mb-0 credits">Developed With Carelon / &nbsp;<a href="https://cloudmedxhealth.com/" className="text-white" target="_blank" rel="noopener noreferrer"> CloudMedX</a></p>
                            </li>
                            <li>
                                <button className="btn btn-wcag"><span><img src="img/double-arrow.png" alt=""/></span>WCAG 2.1 AA</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;
