import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
import { getPercentage } from "../../utils";

export const ZIP_RACE = "ZIP_RACE";
export const ZIP_RACE_SUCCESS = "ZIP_RACE_SUCCESS";
export const ZIP_RACE_FAILURE = "ZIP_RACE_FAILURE";

export const ZipRaceAction = (payload) => async (dispatch) => {
  dispatch({
    type: ZIP_RACE,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/race`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    ZIP_RACE_FAILURE
  );
  if (!apiRequest.error) {
    delete apiRequest.data.result["STATEFIPS"];
    delete apiRequest.data.result["STATEFULLNAME"];
    delete apiRequest.data.result["STATE"];
    delete apiRequest.data.result["ZIPCODE"];
    delete apiRequest.data.result["COUNTY"];
    delete apiRequest.data.result["CITYALIASNAME"];
    delete apiRequest.data.result["CITY"];
    delete apiRequest.data.result["COUNTYFIPS"];
    delete apiRequest.data.result["COUNTY"];
    delete apiRequest.data.result["AVERAGEHOUSEVALUE"];
    delete apiRequest.data.result["HOUSEHOLDSPERZIPCODE"];
    delete apiRequest.data.result["INCOMEPERHOUSEHOLD"];
    delete apiRequest.data.result["PERSONSPERHOUSEHOLD"];
    delete apiRequest.data.result["AVERAGEHOUSEVALUE"];
    delete apiRequest.data.result["FEMALEPOPULATION"];
    delete apiRequest.data.result["MALEPOPULATION"];
    if (!apiRequest.data.result["POPULATION"]) {
      dispatch({ type: ZIP_RACE_SUCCESS, apiResponse: apiRequest.data });
    } else {
      // const total =
      //   Object.values(apiRequest.data.result).reduce((a, b) => a + b) -
      //   apiRequest.data.result["POPULATION"];
      Object.entries(apiRequest.data.result)
        .filter((y, z) => !z == 0)
        .map(([key, value]) => {
          return (apiRequest["data"]["result"][key] = value * 100);
        });
      apiRequest.data.result = Object.entries(apiRequest.data.result)
        .sort((a, b) => parseFloat(b["1"]) - parseFloat(a["1"]))
        .filter((y, z) => !z == 0)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      dispatch({ type: ZIP_RACE_SUCCESS, apiResponse: apiRequest.data });
    }
  }
};
