import React, { useRef, useEffect, useState } from "react";
import "./header.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as ActionTypes from "../actions";
import { SurveyAction } from "../actions";
import * as _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import $ from "jquery";
const Header = ({ survey, SurveyAction }) => {
  const customId = "custom-id-yes";
  const submitSurvey = (e) => {
    e.preventDefault();
    if ($("input[name='expertise']").is(":checked")) {
      // { [m[1].name]: m[1].value }
      // const getQuestions = Object.entries(e.target.elements).filter((m) => {
      //   if (m[1].name) return _.startsWith(m[1].name, "q_") && m[0] != "0";
      // });
      // const getAnswer = getQuestions.map((i) => {
      //   const quetionName = i[0].replace("q_","")

      // });
      const obj = {
        datetime: new Date().toISOString().replace("T", " ").slice(0, 16),
        questions: [
          {
            text: e.target.elements.q_expertise.value,
            answer: [
              document.querySelector(`input[name='expertise']:checked`).value +
              `${document.querySelector(`input[name='expertise']:checked`).value == "Other"
                  ? `, ${e.target.elements.expertise_other.value}`
                  : ""
              }`,
            ],
          },
        ],
      };
      SurveyAction(obj);
      $('[data-toggle="popover"]').popover("hide");
    } else {
      toast("Please Select Your Expertise", {
        toastId: customId,
      });
    }
  };
  useEffect(() => {
    if (survey.data.result) {
      toast("Thank you for your feedback", {
        toastId: customId,
      });
      $("input[name='expertise']").prop("checked", false);
      $("#exampleModal").modal("hide");
    }
  }, [survey]);
  useEffect(() => {
    setTimeout(() => {
      $('[data-toggle="popover"]').popover("show");
    }, 3000);
  }, []);
  return (
      <>
        <section>
          <nav className="navbar navbar-expand-lg navbar-light bg-light top-nav">
            <div className="container-fluid">
              <a className="navbar-brand desktop-logo" href="https://www.thinkanthem.com/" target="_blank">
                {/*<img className="think-logo" src="img/bitmap-copy.png" alt="ThinkAnthem" />*/}
                <img src="img/carelon.png" alt="Carelon" className="" width="150" />
              </a>
              <a className="navbar-brand mobile-logo" href="https://www.thinkanthem.com/" target="_blank">
                <img src="img/carelon.png" alt="Carelon" width="150" />
              </a>
              <a
                  data-toggle="modal"
                  data-target="#exampleModal"
                  href=""
                  className="ml-auto"
                  onClick={() => $('[data-toggle="popover"]').popover("hide")}
              >
                <img
                    src="img/survery-icon.png"
                    alt=""
                    width="40px"
                    height="40px"
                    data-toggle="popover"
                    // data-trigger="hover"
                    data-placement="bottom"
                    data-content="Please take a quick survey."
                />
              </a>
            </div>
          </nav>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item desktop-banner">
                <img src="img/short-hero.jpg" className="d-block w-100 fwc-anim-img-scaleIn" alt="..." />
                <div className="container">
                  <div className="banner-text">
                    <div className="row m-0">
                      <div className="col-md-6 p-0">
                        <div>
                          <h3 className="banner-heading">Health Hits Close To Home</h3>
                          <p className="banner-paragraph text-justify mb-0">
                            A person’s health can be directly impacted by the social factors they face every day.
                            At Carelon Digital Platforms we call these factors Social Drivers of Health (SDOH) instead of Social Determinants.
                            This intentional framing is because these factors influencing health are not fated or destined, but are things that
                            people and communities can overcome or change. Learn more about your community using the map below, and see what
                            Elevance Health is doing to help.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item mobile-banner">
                <img src="img/mobile-hero.jpg" className="d-block w-100" alt="..." />
                <div className="container position-relative">
                  <div className="row mobile-banner-text">
                    <div className="col-md-5">
                      <div>
                        <h3 className="banner-heading-mobile">Health Hits Close To Home</h3>
                        <p className="banner-paragraph-mobile text-justify mb-0">
                          A person’s health can be directly impacted by the social factors they face every day.
                          At Carelon Digital Platforms we call these factors Social Drivers of Health (SDOH) instead of Social Determinants.
                          This intentional framing is because these factors influencing health are not fated or destined, but are things that
                          people and communities can overcome or change. Learn more about your community using the map below, and see what
                          Elevance Health is doing to help.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
            className="modal fade bd-example-modal-lg  "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
          <div className="modal-dialog cstm-dailog" role="document">
            <div className="modal-content modalSurvey">
              <div className="modal-header border-0 bg-blue pt-4 pb-4 d-block">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  {/*<span aria-hidden="true">&times;</span>*/}
                  <span aria-hidden="true">
                  <img src="img/remove.png" width="40px" height="40px" alt="close" />
                </span>
                </button>
                <div className="container">
                  <h3>Please tell us a little more about you</h3>
                  <p>
                    We appreciate you taking the time to help Close to Home better understand our audience. Your feedback
                    will allow us to optimize our website and provide you with additional resources in the future. Thank
                    you.
                  </p>
                </div>
              </div>
              <div className="modal-body surveyForm container">
                <form onSubmit={(e) => submitSurvey(e)}>
                  <div className="row">
                    <div className="col-md-12 bold surveyFormQuestion">
                      <p>
                        <b>Please select one option which best describes your profession or field of expertise.</b>
                        <input
                            type="hidden"
                            name="q_expertise"
                            value="Please select one option which best describes your profession or field of expertise"
                        />
                      </p>
                    </div>
                    <div className="col-md-12 mb-5">
                      <ul className="p-0 m-0">
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Journalist or media professional
                            <input type="radio" name="expertise" value="Journalist or media professional" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Health Policy Professional
                            <input type="radio" name="expertise" value="Health Policy Professional" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Public health professional (state, local, or community level)
                            <input
                                type="radio"
                                name="expertise"
                                value="Public health professional (state, local, or community level)"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Health care provider or administrator
                            <input type="radio" name="expertise" value="Health care provider or administrator" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Member of an advocacy group or trade organization
                            <input
                                type="radio"
                                name="expertise"
                                value="Member of an advocacy group or trade organization"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Academic, student, or researcher
                            <input type="radio" name="expertise" value="Academic, student, or researcher" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Government administrator, legislator, or staffer
                            <input
                                type="radio"
                                name="expertise"
                                value="Government administrator, legislator, or staffer"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio">
                            Curious citizen
                            <input type="radio" name="expertise" value="Curious citizen" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li className="list-unstyled">
                          <label className="sq-radio d-inline ">
                            Other
                            <input type="radio" name="expertise" value="Other" />
                            <span className="checkmark"></span>
                          </label>
                          <input type="text" className="d-inline borderlessInput" name="expertise_other" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button type="submit" className="btn cstm-submit pl-3 mr-3" disabled={survey.isLoading}>
                    Submit{" "}
                    {survey.isLoading && (
                        <i className=" float-right surveyButtonArrow">
                          <ClipLoader loading={survey.isLoading} color="#ffffff" size={20} />
                        </i>
                    )}
                  </button>
                  <button type="button" className="btn cstm-cancel pl-3" data-dismiss="modal">
                    Cancel
                  </button>
                </form>
              </div>

              <div className="modal-footer border-0 text-center d-block"></div>
            </div>
          </div>
        </div>
      </>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps, {
  SurveyAction,
})(withRouter(Header));
