import { Tooltip, OverlayTrigger, Col } from "react-bootstrap";
import TooltipIcon from "../../assets/images/TooltipIcon.svg";
import { isNull } from "lodash";

const StateBlock = (props) => (
  <>
    <Col md={6} xs={12} key={props.title}>
      <div className="TabBodyList">
        <p className="fw-bold text-wrap mb-0 pb-0 tb-label">
          {props.title}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <div dangerouslySetInnerHTML={{ __html: props.tooltip }} />
              </Tooltip>
            }
          >
            <img src={TooltipIcon} style={{ height: 17, width: 20 }} />
          </OverlayTrigger>

          {/*  <img*/}
          {/*  src={TooltipIcon}*/}
          {/*  style={{ height: 17, width: 20 }}*/}
          {/*  data-toggle="tooltip"*/}
          {/*  data-placement="top"*/}
          {/*  title= {props.tooltipInfo}*/}
          {/*/>*/}
        </p>
        {props && props.totalValue && props.perValue && !isNull(props.totalValue) && !isNull(props.perValue) && (
          <p className="text-wrap tb-value">
            {props.perValue && (parseFloat(props.perValue) * 100).toFixed(2)}% ({props.totalValue})
          </p>
        )}

        {props && !props.totalValue && props.perValue > 0 && !isNull(props.perValue) && props.perValue && (
          <p className="text-wrap tb-value">{parseFloat(props.perValue).toFixed(2)}%</p>
        )}
        {props && props.exactValue ? (
          <p className="text-wrap tb-value">${props.exactValue.toLocaleString()}</p>
        ) : props.onlyPer ? (
          <p className="text-wrap tb-value">{(props.onlyPer * 100).toFixed(2)}%</p>
        ) : props.totalValue ? (
          <p className="text-wrap tb-value">{props.totalValue.toFixed(2)}</p>
        ) : (
          (!props.perValue || isNull(props.perValue) || isNull(props.totalValue)) && (
            <p className="text-wrap tb-value">Not Available</p>
          )
        )}
      </div>
    </Col>
  </>
);
export default StateBlock;
