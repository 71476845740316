import React from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Breadcrumb,
  Tabs,
  Tab,
  Nav,
  InputGroup,
  Form,
  Tooltip,
  FormControl,
  FormLabel,
  OverlayTrigger,
} from "react-bootstrap";
const BreadCrumb = ({ handleClosePopUps, map, SearchOnMap, getStateName, food, whiteBg = false }) => {
  return (
    <Breadcrumb className={`${whiteBg == true ? "whiteBreadCrumb" : ""}`}>
      <Breadcrumb.Item
        onClick={() => {
          handleClosePopUps(false, true);
          map.flyTo({
            center: [-1.3992113533350334, -1.9811031734521123],
            zoom: 4.0,
            essential: true,
          });
        }}
      >
        U.S.
      </Breadcrumb.Item>
      <Breadcrumb.Item
        onClick={() => {
          SearchOnMap(getStateName(food.stateFood.result.state));
        }}
      >
        {" "}
        {getStateName(food.stateFood && food.stateFood.result && food.stateFood.result.state)}
      </Breadcrumb.Item>
      {food.countyFood && food.countyFood.result && (
        <Breadcrumb.Item active>{food.countyFood.result && food.countyFood.result.county}</Breadcrumb.Item>
      )}
      {food && food.zipFood.result && (
        <Breadcrumb.Item active>{food.zipFood.result && food.zipFood.result.POSTL_ZIP_CD}</Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default BreadCrumb;
