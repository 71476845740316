import axios from "axios";
import moment from "moment";
import { getConfig } from "../environment";
let { API_HOST } = getConfig();


// export default instance;
const fetchClient = () => {
  const defaultOptions = {
    baseURL: API_HOST,
    headers: {
      'Content-Type': 'application/json',
    },
  };
return  axios.create(defaultOptions);

};
export default fetchClient();
