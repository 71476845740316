import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
export const COUNTY_GGPH = "COUNTY_GGPH";
export const COUNTY_GGPH_SUCCESS = "COUNTY_GGPH_SUCCESS";
export const COUNTY_GGPH_FAILURE = "COUNTY_GGPH_FAILURE";

export const CountyGgphAction = (payload) => async (dispatch) => {
  dispatch({
    type: COUNTY_GGPH,
  });

  const apiRequest = await ManualAuthApiCall(
    `Anthem/ggph`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    COUNTY_GGPH_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: COUNTY_GGPH_SUCCESS, apiResponse: apiRequest.data });
  }
};
