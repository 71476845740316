import * as ActionTypes from "../actions";

const initialState = {
  isLoading: false,
  data: [],
  apiErrors: [],
  error: false,
};

const SurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SURVEY:
      return { ...state, isLoading: true };

    case ActionTypes.SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        apiErrors: null,
      };

    case ActionTypes.SURVEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: true,
        apiErrors: action.apiErrors,
      };
    default:
      return state;
  }
};

export default SurveyReducer;
