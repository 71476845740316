import { ManualAuthApiCall } from "../../middlewares/apiMiddleware";
export const ZIP_GGPH = "ZIP_GGPH";
export const ZIP_GGPH_SUCCESS = "ZIP_GGPH_SUCCESS";
export const ZIP_GGPH_FAILURE = "ZIP_GGPH_FAILURE";

export const ZipGgphAction = (payload) => async (dispatch) => {
  dispatch({
    type: ZIP_GGPH,
  });
  const apiRequest = await ManualAuthApiCall(
    `Anthem/ggph`,
    {
      method: "GET",
      body: payload,
    },
    dispatch,
    ZIP_GGPH_FAILURE
  );
  if (!apiRequest.error) {
    dispatch({ type: ZIP_GGPH_SUCCESS, apiResponse: apiRequest.data });
  }
};
