import React, { Component } from "react";
import "./ToogleSwitch.scss";

const ToggleSwitch = (props) => (
  <div className="toggle-switch">
    <label className="switch mr-2">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={props.name}
        id={props.name}
        onChange={(e) => {
          props.showPopUps && props.showPopUps(e);
        }}
        defaultChecked={props.defaultValue}
        disabled={props.disabled}
      />
      <span className="slider round"></span>
    </label>

    <span className="ToggleText">
      Compare {props.name.replace("_", " ").toUpperCase()}
    </span>
  </div>
);

export default ToggleSwitch;
