import * as _ from "lodash";
// import { isUndefined } from 'lodash';

export function isLoggedIn() {
  return !_.isNull(localStorage.getItem("x-auth"))
    ? localStorage.getItem("x-auth")
    : false;
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export const getToken = () => {
  if (_.isNull(localStorage.getItem("x-auth"))) return null;
  return `Bearer ${JSON.parse(localStorage.getItem("x-auth")).token}`;
};
export const checkBrowser = () => {
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};

export const toQueryString = (paramsObject) =>
  Object.keys(paramsObject)
    .map(
      (key) =>
        `${encodeURI(key)}=${encodeURI(JSON.stringify(paramsObject[key]))}`
    )
    .join("&");

export const getPercentage = (total, current) => {

  const per = parseFloat((current * 100) / parseInt(total)).toFixed(2);
  return per;
};
